import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchableItemsListPage } from 'src/app/shared/modals/searchable-items-list/searchable-items-list.page';

@Component({
  selector: 'app-material-manager',
  templateUrl: './material-manager.component.html',
  styleUrls: ['./material-manager.component.scss'],
})
export class MaterialManagerComponent implements OnInit {
  // I have to make a copy so that I can edit
  elmersScale: string = '';
  elmersManual: string = '';
  elmersVendor: string = '';

  @Input() title: string;
  @Input() elmers_scale: string = '0';
  @Input() elmers_manual: string = '0';
  @Input()  elmers_vendor: string = '0'
  
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.elmersScale = this.elmers_scale;
    this.elmersManual = this.elmers_manual;
    this.elmersVendor = this.elmers_vendor;
  }

  decreaseQuantityScale() {
    if (+this.elmersScale > 0) {
      this.elmersScale = +this.elmersScale - 1 + '';
    }
  }
  increaseQuantityScale() {
    this.elmersScale = +this.elmersScale + 1 + '';
  }
  decreaseQuantityManual() {
    if (+this.elmersManual > 0) {
      this.elmersManual = +this.elmersManual- 1 + '';
    }
  }
  increaseQuantityManual() {
    this.elmersManual = +this.elmersManual + 1 + '';
  }
  decreaseQuantityVendor() {
    if (+this.elmersVendor > 0) {
      this.elmersVendor = +this.elmersVendor - 1 + '';
    }
  }
  increaseQuantityVendor() {
    this.elmersVendor = +this.elmersVendor + 1 + '';
  }

  close() {
    this.modalController.dismiss();
  }
  submitItems() {
    /*this.modalController.dismiss({
      quantity: this.selectedQuantity,
    });*/
    this.modalController.dismiss({
      elmers_scale : this.elmersScale,
      elmers_manual: this.elmersManual,
      elmers_vendor: this.elmersVendor
    })
  }
}
