import * as FormsActions from './forms.actions';
import { FormsStateInterface, initFormsState } from './forms.state';

export function FormsReducer(
  state = initFormsState,
  action
): FormsStateInterface {
  switch (action.type) {
    case FormsActions.FormsInitReceiveAction.type: {
      const payload = action.payload.formToken;
      return {
        ...state,
        activeFormToken: payload,
      };
    }

    default:
      return state;
  }
}
