import { createAction, props } from '@ngrx/store';

export const PedigreeEntriesRequestAction = createAction(
  '[Pedigree] Entries Request',
  props<{ payload: { startDate: string; endDate: string; userId: string } }>()
);

export const PedigreeEntriesReceiveAction = createAction(
  '[Pedigree] Entries Receive',
  props<{ payload: any }>()
);
