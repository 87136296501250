import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobBackend } from './job.backend';

@Injectable()
export class JobService extends BaseService {
  constructor(private jobBackend: JobBackend) {
    super();
  }

  getAllJobs(userId: string): Observable<any> {
    return this.jobBackend
      .fetchAllJobs(userId)
      .pipe(map((response) => response.body));
  }

  getJobDetails(userId: string, jobId): Observable<any> {
    return this.jobBackend
      .fetchJobDetails(userId, jobId)
      .pipe(map((response) => response.body));
  }
}
