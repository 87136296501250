import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as PlantActions from './plant.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as PlantSelectors from './plant.selectors';
import { PlantModel } from '../backend/plant/model/plant.model';
import * as FormsActions from '../forms/forms.actions';

@Injectable()
export class PlantStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public requestAllPlants() {
    this.store.dispatch(PlantActions.RequestActivePlantsAction());
  }
  public getAllPlants(): Observable<PlantModel[]> {
    return this.store.pipe(
      select((state) => PlantSelectors.getAllPlants(state))
    );
  }

  public requestMSHAInspections() {
    this.store.dispatch(PlantActions.RequestMSHAInspectionsAction());
  }
  public getAllMSHAInspections(): Observable<PlantModel[]> {
    return this.store.pipe(
      select((state) => PlantSelectors.getMSHAInspections(state))
    );
  }

  public submitInspection(
    answers: string,
    userId: string,
    plantId: string
  ) {
    this.store.dispatch(
      PlantActions.SubmitInspectionRequestAction({
        payload: { answers, userId, plantId },
      })
    );
  }
}
