import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationBackend } from './location.backend';

@Injectable()
export class LocationService extends BaseService {
  constructor(private locationBackend: LocationBackend) {
    super();
  }

  // getAllLocations(): Observable<any> {
  //   return this.locationBackend
  //     .fetchAllLocations()
  //     .pipe(map((response) => response.body));
  // }
}
