import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Injectable()
export class PedigreeBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    'content-type': 'application/json',
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  // tslint:disable-next-line:variable-name
  fetchPedigreeEntries(
    start_date: string,
    end_date: string,
    user_id: string
  ): Observable<HttpResponse<any>> {
    const data = {
      start_date,
      end_date,
      user_id: Number(user_id),
    };
    const url = `${this.API_ROOT}/time-manager/get-hours`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
