import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormsBackend } from './forms.backend';

@Injectable()
export class FormsService extends BaseService {
  timeEntries$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private formsBackend: FormsBackend) {
    super();
  }

  startAudit(
    startDate: string,
    auditorName: string,
    formId: string
  ): Observable<any> {
    return this.formsBackend
      .fetchStartAudit(startDate, auditorName, formId)
      .pipe(map((response) => response.body));
  }

  submitAuditResponses(
    auditToken: string,
    answers: any,
    positiveResponse: string,
    userId: string
  ): Observable<any> {
    return this.formsBackend
      .fetchSubmitResponses(auditToken, answers, positiveResponse, userId)
      .pipe(map((response) => response.body));
  }
}
