import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AuthSessionActions from './auth-session.actions';
import * as ErrorActions from '../../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { AuthService } from '../../backend/auth/auth.service';
import { UserCredentials } from '../../backend/auth/model/user-creds.model';
import { Router } from '@angular/router';
import { AlertService } from '../../backend/alert-service/alert.service';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { AuthStoreService } from '../auth.service';
import { ErrorStoreService } from '../../error/error.service';
import { ToastController } from '@ionic/angular';

@Injectable()
export class AuthSessionEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private authStoreService: AuthStoreService,
    private toastController: ToastController
  ) {}

  @Effect()
  OnLoginRequest$ = this.actions$.pipe(
    ofType(AuthSessionActions.LoginRequestAction),
    map((action) => action.payload),
    throttleTime(1000),
    switchMap((credentials: UserCredentials) =>
      from(this.authService.login(credentials)).pipe(
        tap(() => {
          this.authStoreService.loginResolved.next();
        }),
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(AuthSessionActions.LoginReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnChangeTemporaryPasswordRequest$ = this.actions$.pipe(
    ofType(AuthSessionActions.ChangeTemporaryPasswordRequestAction),
    map((action) => action.payload),
    throttleTime(1000),
    switchMap(({ user_id, password, jwtToken }) =>
      from(this.authService.changeTempPass(user_id, password, jwtToken)).pipe(
        tap(() => {
          this.authStoreService.loginResolved.next();
        }),
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(AuthSessionActions.LoginReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnPasswordResetRequest$ = this.actions$.pipe(
    ofType(AuthSessionActions.PasswordResetRequestAction),
    map((action) => action.payload),
    throttleTime(1000),
    switchMap(({ username, password, year }) =>
      from(this.authService.resetPassword(username, password, year)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(AuthSessionActions.PasswordResetReceiveAction()),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  PasswordResetReceive$ = this.actions$.pipe(
    ofType(AuthSessionActions.PasswordResetReceiveAction),
    map(async () => {
      const toast = await this.toastController.create({
        message: 'You can now log in with your new password',
        duration: 1500,
        color: 'success',
        position: 'top',
      });
      toast.present();
    })
  );

  @Effect({ dispatch: false })
  OnLoginReceive$ = this.actions$.pipe(
    ofType(AuthSessionActions.LoginReceiveAction),
    map((action) => action.payload),
    map((payload: any) => {
      if (!payload.result.isPasswordResetRequired) {
        this.localStorageService.removeItem('user_data');
        this.localStorageService.removeItem('external_user_id');
        this.localStorageService.removeItem('location_id');
        this.localStorageService.removeItem('id');
        this.localStorageService.removeItem('name');
        this.localStorageService.removeItem('jwt');
        this.localStorageService.removeItem('role_id');
        this.localStorageService.setItem('role_id', payload.result.role_id);
        this.localStorageService.setItem(
          'user_data',
          JSON.stringify(payload.result)
        );
        this.localStorageService.setItem(
          'external_user_id',
          payload.result.external_user_id
        );
        this.localStorageService.setItem(
          'location_id',
          payload.result.location_id
        );
        this.localStorageService.setItem('id', payload.result.id);
        this.localStorageService.setItem(
          'name',
          `${payload.result.first_name} ${payload.result.last_name}`
        );
        this.localStorageService.setItem('jwt', payload.token);
        this.router.navigate(['/dashboard'], {
          state: {
            resetWeekIndex: true,
          },
        });
      } else {
        this.authStoreService.tempPassChangeRequired.next({
          user_id: payload.result.id,
          jwtToken: payload.token,
        });
      }
    })
  );

  @Effect({ dispatch: false })
  OnLogoutRequest$ = this.actions$.pipe(
    ofType(AuthSessionActions.LogoutRequestAction),
    map((action) => {
      this.localStorageService.removeItem('user_data');
      this.localStorageService.removeItem('external_user_id');
      this.localStorageService.removeItem('location_id');
      this.localStorageService.removeItem('id');
      this.localStorageService.removeItem('name');
      this.localStorageService.removeItem('jwt');
      this.localStorageService.removeItem('role_id');
      // this.localStorageService.setItem('jwt', payload.token);
      this.router.navigate(['']);
    })
  );

  @Effect()
  OnForgotPasswordRequest$ = this.actions$.pipe(
    ofType(AuthSessionActions.ForgotPasswordRequestAction),
    map((action) => action.payload.email),
    throttleTime(2000),
    switchMap((email: string) =>
      from(this.authService.forgotPassword(email)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(AuthSessionActions.ForgotPasswordReceiveAction()),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnForgotPasswordReceive$ = this.actions$.pipe(
    ofType(AuthSessionActions.ForgotPasswordReceiveAction),
    map(() => {
      this.alertService.presentAlert('Success', 'Please check your email.', [
        'Close',
      ]);
    })
  );
}
