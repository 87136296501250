import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as LocationActions from './location.actions';
import * as ErrorActions from '../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { LocationService } from '../backend/location/location.service';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private locationService: LocationService
  ) {}

  // @Effect()
  // OnGetAllLocationsRequest$ = this.actions$.pipe(
  //   ofType(LocationActions.RequestAllLocationsAction),
  //   throttleTime(2000),
  //   switchMap(() =>
  //     from(this.locationService.getAllLocations()).pipe(
  //       mergeMap((data: any) =>
  //         iif(
  //           () => !!data && data.status !== 'error',
  //           of(LocationActions.ReceiveAllLocationsAction({ payload: data })),
  //           of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
  //         )
  //       )
  //     )
  //   )
  // );
}
