import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchableItemsListPage } from 'src/app/shared/modals/searchable-items-list/searchable-items-list.page';

@Component({
  selector: 'app-add-edit-note',
  templateUrl: './add-edit-note.component.html',
  styleUrls: ['./add-edit-note.component.scss'],
})
export class AddEditNoteComponent implements OnInit {
  note: any = {
    note: '',
    created_by: JSON.parse(localStorage.getItem('user_data')).id,
    created_by_user: `${JSON.parse(localStorage.getItem('user_data')).first_name} ${JSON.parse(localStorage.getItem('user_data')).last_name}`,
    updated: new Date(),
    new: true
  };
  isEdit = false;

  @Input() selectedNote: any;
  @Input() index: number;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.selectedNote) {
      this.isEdit = true;
      this.note = this.selectedNote;
      this.note = {...this.note, ...{note: this.note.note}};
    } else {
      this.note = {...this.note, ...{index: this.index}};
    }

    console.log(this.note);
  }

  close() {
    this.modalController.dismiss();
  }
  submitItems() {
    this.modalController.dismiss({
      note: this.note
    });
  }
}
