import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DepartmentModel } from '../backend/department/model/department.model';

export interface DepartmentStateInterface
  extends EntityState<DepartmentModel> {}

export const adapterDepartment: EntityAdapter<DepartmentModel> = createEntityAdapter<
  DepartmentModel
>();

export const initDepartmentState: DepartmentStateInterface = adapterDepartment.getInitialState(
  {}
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterDepartment.getSelectors();
