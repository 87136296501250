import { createAction, props } from '@ngrx/store';
import { TimeEntryModel } from '../backend/time-entry/model/time-entry.model';

export const TimeEntriesRequestAction = createAction(
  '[Time Entry] Entries Request',
  props<{
    payload: { startDate: string; endDate: string; employeeId: string };
  }>()
);

export const TimeEntriesReceiveAction = createAction(
  '[Time Entry] Entries Receive',
  props<{ payload: any }>()
);

export const SetActiveDateAction = createAction(
  '[Time Entry] Set Active Date',
  props<{ payload: { activeDate: string } }>()
);

export const GetActiveDateAction = createAction(
  '[Time Entry] Get Active Date',
  props<{ payload: { activeDate: string } }>()
);

export const ClearActiveDateAction = createAction(
  '[Time Entry] Clear Active Date'
);

export const SetEditedTimeEntryAction = createAction(
  '[Time Entry] Set Edited Time Entry',
  props<{ payload: { timeEntry: TimeEntryModel } }>()
);

export const GetEditedTimeEntryAction = createAction(
  '[Time Entry] Get Edited Time Entry',
  props<{ payload: { timeEntry: TimeEntryModel } }>()
);

export const ClearEditedTimeEntryAction = createAction(
  '[Time Entry] Clear Edited Time Entry'
);

export const SetPendingTimeEntriesAction = createAction(
  '[Time Entry] Set Pending Time Entries',
  props<{ payload: { timeEntries: TimeEntryModel[] } }>()
);

export const GetPendingTimeEntriesAction = createAction(
  '[Time Entry] Get Pending Time Entries',
  props<{ payload: { timeEntries: TimeEntryModel[] } }>()
);

export const ClearPendingTimeEntriesAction = createAction(
  '[Time Entry] Clear Pending Time Entries'
);

export const UpdateTimeEntriesRequestAction = createAction(
  '[Time Entry] Update Time Entries Request',
  props<{
    payload: {
      timeEntries: TimeEntryModel[];
      status: string;
      time_difference: string | null;
    };
  }>()
);

export const UpdateTimeEntriesReceiveAction = createAction(
  '[Time Entry] Update Time Entries Receive',
  props<{
    payload: {
      timeEntries: TimeEntryModel[];
      status: string;
      updateType: string;
    };
  }>()
);

export const DeleteTimeEntryRequestAction = createAction(
  '[Time Entry] Delete Request',
  props<{ payload: { timeEntryId: string } }>()
);

export const DeleteTimeEntryReceiveAction = createAction(
  '[Time Entry] Delete Receive',
  props<{ payload: { timeEntryId: string } }>()
);

export const AddTimeEntryPhotosRequestAction = createAction(
  '[Time Entry] Add Photos Request',
  props<{
    payload: { base64Url: string };
  }>()
);

export const AddTimeEntryPhotosReceiveAction = createAction(
  '[Time Entry] Add Photos Receive',
  props<{ payload: { imageName: string; base64Url: string } }>()
);

export const TimeEntryPhotosRequestAction = createAction(
  '[Time Entry] Photos Request',
  props<{
    payload: { id: string; time_entry_photo: string }[];
  }>()
);

export const TimeEntryPhotosReceiveAction = createAction(
  '[Time Entry] Photos Receive',
  props<{ payload: any }>()
);

export const DeleteTimeEntryPhotosRequestAction = createAction(
  '[Time Entry] Delete Photos Request',
  props<{
    payload: { imagesNames: string[] };
  }>()
);

export const DeleteTimeEntryPhotosReceiveAction = createAction(
  '[Time Entry] Delete Photos Receive',
  props<{ payload: { imagesNames: string[] } }>()
);

export const AddNonPedigreeTimeRequestAction = createAction(
    '[Time Entry] Non Pedigree Time Add Request',
    props<{ payload: { user_id, formattedTimeIntervals, deletedTimeIntervalIds } }>()
);

export const AddNonPedigreeTimeReceiveAction = createAction(
    '[Time Entry] Non Pedigree Time Add Receive',
    props<{ payload: any }>()
);

export const GetNonPedigreeTimeRequestAction = createAction(
    '[Time Entry] Non Pedigree Time Get Request',
    props<{ payload: { user_id: string, start_hours: string, end_hours: string } }>()
);

export const GetNonPedigreeTimeReceiveAction = createAction(
    '[Time Entry] Non Pedigree Time Get Receive',
    props<{ payload: any }>()
);

export const PTORequestAction = createAction(
  '[Time Entry] PTO Request',
  props<{ payload: { user_id: string, start_date: string, end_date: string } }>()
);

export const PTOReceiveAction = createAction(
  '[Time Entry] PTO Receive',
  props<{ payload: any }>()
);