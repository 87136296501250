import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PedigreeBackend } from './pedigree.backend';

@Injectable()
export class PedigreeService extends BaseService {
  constructor(private pedigreeBackend: PedigreeBackend) {
    super();
  }

  getPedigreeHours(
    startDate: string,
    endDate: string,
    userId: string
  ): Observable<any> {
    return this.pedigreeBackend
      .fetchPedigreeEntries(startDate, endDate, userId)
      .pipe(map((response) => response.body));
  }
}
