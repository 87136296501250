import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as JobActions from '../job/job.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as JobSelectors from '../job/job.selectors';
import { JobModel } from '../backend/job/model/job.model';

@Injectable()
export class JobStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public requestJobs(userId: string) {
    this.store.dispatch(
      JobActions.RequestAllJobsAction({ payload: { userId } })
    );
  }
  public getAllJobs(): Observable<JobModel[]> {
    return this.store.pipe(select((state) => JobSelectors.getAllJobs(state)));
  }
  public requestJobDetails(userId: string, jobId: string) {
    this.store.dispatch(
      JobActions.RequestJobDetailsAction({ payload: { userId, jobId } })
    );
  }
  public getSelectedJobMaterials(): Observable<any> {
    return this.store.pipe(
      select((state) => JobSelectors.getSelectedJobMaterials(state))
    );
  }
  public getSelectedJobEquipment(): Observable<any> {
    return this.store.pipe(
      select((state) => JobSelectors.getSelectedJobEquipment(state))
    );
  }
  public getSelectedJobCostCodes(): Observable<any> {
    return this.store.pipe(
      select((state) => JobSelectors.getSelectedJobCostCodes(state))
    );
  }
}
