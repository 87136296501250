import * as PlantActions from './plant.actions';
import {
  adapterPlant,
  initPlantState,
  PlantStateInterface
} from './plant.state';

export function PlantReducer(
  state = initPlantState,
  action
): PlantStateInterface {
  switch (action.type) {
    case PlantActions.ReceiveActivePlantsAction.type: {
      const payload = action.payload.data;
      return adapterPlant.upsertMany(payload, { ...state });
    }

    case PlantActions.ReceiveMSHAInspectionsAction.type: {
      const payload = action.payload.result;
      return  {...state, ...{
        mshaInspections: payload
        }};
    }

    default:
      return state;
  }
}
