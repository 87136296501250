import { ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../auth/auth.reducer';
import * as fromError from '../error/error.reducer';
import * as fromTimeEntry from '../time-entry/time-entry.reducer';
import * as fromJob from '../job/job.reducer';
import * as fromForms from '../forms/forms.reducer';
import * as fromPedigree from '../pedigree/pedigree.reducer';
import * as fromDepartment from '../department/department.reducer';
import * as fromLocation from '../location/location.reducer';
import * as fromSchedule from '../schedule/schedule.reducer';
import { routerReducer } from '@ngrx/router-store';
import { SelectAppState } from './store.state';
import * as AuthSessionActions from '../auth/auth-session/auth-session.actions';
import * as fromPlant from '../plant/plant.reducer';
import * as fromFuelLogs from '../fuel-logs/fuel-log.reducer';
import * as fromSchedule2 from '../schedule2/schedule.reducer';

export const AppReducers: ActionReducerMap<SelectAppState> = {
  auth: fromAuth.reducers,
  router: routerReducer,
  error: fromError.ErrorReducer,
  timeEntry: fromTimeEntry.TimeEntryReducer,
  job: fromJob.JobReducer,
  forms: fromForms.FormsReducer,
  pedigree: fromPedigree.PedigreeReducer,
  department: fromDepartment.DepartmentReducer,
  location: fromLocation.LocationReducer,
  schedule: fromSchedule.ScheduleReducer,
  plant: fromPlant.PlantReducer,
  fuelLogs: fromFuelLogs.FuelLogReducer,
  schedule2: fromSchedule2.Schedule2Reducer
};

export const APP_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<SelectAppState>
>('App Reducers');

// Can be used in the future if we want to sync with local storage
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [clearState]
  : [clearState];

export function clearState(reducer) {
  return (state, action) => {

    if (action.type === AuthSessionActions.LogoutRequestAction.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
