import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlantModel } from '../backend/plant/model/plant.model';

export interface PlantStateInterface extends EntityState<PlantModel> {
  mshaInspections: any[];
}

export const adapterPlant: EntityAdapter<PlantModel> = createEntityAdapter<
  PlantModel
>();

export const initPlantState: PlantStateInterface = adapterPlant.getInitialState(
  {
    mshaInspections: []
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterPlant.getSelectors();
