import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class LocationBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    // 'content-type': 'application/json',
    //  'enctype': 'multipart/form-data',
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  // fetchAllLocations(): Observable<HttpResponse<any>> {
  //   const url = `${this.API_ROOT}/location/lists`;
  //   return this.http.get<any[]>(url, this.getOptions());
  // }

  fetchLocationsByData(
    id,
    department_code = null
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/location/lists`;
    const formData = new FormData();
    formData.append('user_id', id);
    formData.append('department_code', department_code);
    return this.http.post<{
      locations: any[];
      default_location: string;
      default_location_code: string;
    }>(url, formData, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
