import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserCredentials } from './model/user-creds.model';

@Injectable()
export class AuthBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    // 'content-type': 'application/json',
    //  'enctype': 'multipart/form-data',
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchLogin(userCredentials: UserCredentials): Observable<HttpResponse<any>> {
    console.log(userCredentials);
    const formData = new FormData();
    formData.append('email', userCredentials.email);
    formData.append('password', userCredentials.password);
    formData.append('remember', userCredentials.remember?.toString());
    formData.append('device_type', userCredentials.device_type);
    formData.append('player_id', userCredentials.player_id);
    formData.append('device_token', userCredentials.device_token);
    const url = `${this.API_ROOT}/auth/login`;
    return this.http.post<any[]>(url, formData, this.getOptions());
  }

  fetchChangeTemporaryPassword(
    user_id: string,
    password: string,
    jwtToken: string
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/auth/change-password`;
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('password', password);
    return this.http.post<any[]>(url, formData, {
      headers: {
        'Auth-Token':
          '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
        Authorization: `${jwtToken}`,
      },
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    });
  }

  fetchResetPassword(
      username: string,
      password: string,
      year: string
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/auth/reset-password`;
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('year', year);
    return this.http.post<any[]>(url, formData, {
      headers: {
        'Auth-Token':
            '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
      },
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    });
  }

  fetchForgotPassword(email: string): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('forgot', 'true');
    const url = `${this.API_ROOT}/auth/forgot-password`;
    return this.http.post<any[]>(url, formData, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
