import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as DepartmentActions from './department.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as DepartmentSelectors from './department.selectors';
import { DepartmentModel } from '../backend/department/model/department.model';

@Injectable()
export class DepartmentStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public requestAllDepartments() {
    this.store.dispatch(DepartmentActions.RequestAllDepartmentsAction());
  }
  public getAllDepartments(): Observable<DepartmentModel[]> {
    return this.store.pipe(
      select((state) => DepartmentSelectors.getAllDepartments(state))
    );
  }
}
