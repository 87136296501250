import { createSelector } from '@ngrx/store';
import { getTimeEntriesState } from '../store/store.selectors';
import { TimeEntryStateInterface, selectAll } from './time-entry.state';
import { TimeEntryModel } from '../backend/time-entry/model/time-entry.model';
import * as moment from 'moment';
import { getActiveUser } from '../auth/auth-session/auth-session.selectors';
import { UserBackendModel } from '../backend/auth/model/user-backend.model';
import { of } from 'rxjs';

export const getAllTimeEntries = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => selectAll(state)
);

export const getActiveStartDate = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.activeRangeStartDate
);

export const getActiveEndDate = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.activeRangeEndDate
);

export const getActiveDate = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.activeDate
);

export const getEditedTimeEntry = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.editedTimeEntry
);

export const getPendingTimeEntries = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.pendingTimeEntries
);

export const getActiveEntries = createSelector(
  getAllTimeEntries,
  getActiveStartDate,
  getActiveEndDate,
  (allEntries: TimeEntryModel[], startDate: string, endDate: string) => {
    const rangeStartDate = moment(startDate, 'MM/DD/YYYY').subtract(1, 'days');
    const rangeEndDate = moment(endDate, 'MM/DD/YYYY').add(1, 'days');
    return allEntries.filter((timeEntry) =>
      moment(timeEntry.clockin_date, 'YYYY/MM/DD').isBetween(
        rangeStartDate,
        rangeEndDate,
        'date'
      )
    );
  }
);

export const getWeeklyTotalHours = createSelector(
  getActiveEntries,
  getActiveStartDate,
  (allActiveEntries: TimeEntryModel[], startDate: string) => {
    const msPerHr = 3600000;
    const totalTime = moment.duration('00:00');
    const submittedEntries = allActiveEntries.filter(
      (e) =>
        e.is_approved === '3' || e.is_approved === '5' || e.is_approved === '1'
    );
    for (const timeEntry of submittedEntries) {
      const time = timeEntry.hours * msPerHr;
      totalTime.add(moment.duration(time));
    }
    const totalTimeInMins = totalTime.asMilliseconds() / 60000;
    let hrs = Math.floor(totalTimeInMins / 60).toString();
    if (hrs.toString().length < 2) {
      hrs = `0${hrs}`;
    }
    let mins = Math.round(totalTimeInMins % 60).toString();
    if (mins.toString().length < 2) {
      mins = `0${mins}`;
    }
    return `${hrs}:${mins}`;
  }
);

export const getTimeEntriesByDate = createSelector(
  getActiveEntries,
  (activeTimeEntries: TimeEntryModel[], date: string) => {
    return activeTimeEntries.filter(
      (timeEntry) => timeEntry.created_date === date
    );
  }
);

export const getAllTimeEntriesPhotos = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.images
);

export const getNonPedigreeTimes = createSelector(
    getTimeEntriesState,
    (state: TimeEntryStateInterface) => state.nonPedigreeTimes
);

export const getPTOTimes = createSelector(
  getTimeEntriesState,
  (state: TimeEntryStateInterface) => state.ptoTimes
);
