import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlantBackend } from './plant.backend';
import { ToastService } from '../toast-service/toast.service';

@Injectable()
export class PlantService extends BaseService {
  constructor(private plantBackend: PlantBackend, private toastService: ToastService) {
    super();
  }

  getActivePlants(): Observable<any> {
    return this.plantBackend
      .fetchActivePlants()
      .pipe(map((response) => response.body));
  }

  getPlantInspections(): Observable<any> {
    return this.plantBackend
      .fetchMSHAInspections()
      .pipe(map((response) => response.body));
  }

  submitInspection(
    answers: any,
    userId: string,
    plantId: string
  ): Observable<any> {
    return this.plantBackend
      .fetchSubmitInspection(answers, userId, plantId)
      .pipe(
        tap((data) => {
          if (data.status !== ('error') as any) {
            this.toastService.presentToast('Inspection submitted successfully!', 'success');
          }
        }),
        map((response) => response.body));
  }
}
