import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ErrorActions from '../error/error.actions';
import { map } from 'rxjs/operators';
import { ErrorStoreService } from './error.service';

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private errorService: ErrorStoreService
  ) {}

  @Effect({ dispatch: false })
  OnErrorReceive$ = this.actions$.pipe(
    ofType(ErrorActions.ErrorReceiveAction),
    map((action: any) => this.errorService.displayError(action.payload))
  );
}
