import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as FormsActions from './forms.actions';
import * as ErrorActions from '../error/error.actions';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../backend/alert-service/alert.service';
import { FormsService } from '../backend/forms/forms.service';

@Injectable()
export class FormsEffects {
  constructor(
    private actions$: Actions,
    private formsService: FormsService,
    private router: Router,
    private alertService: AlertService
  ) {}

  @Effect()
  OnStartForm$ = this.actions$.pipe(
    ofType(FormsActions.FormsInitRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.formsService.startAudit(
          requestDetails.date,
          requestDetails.userId,
          requestDetails.formId
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(
              FormsActions.FormsInitReceiveAction({
                payload: { formToken: data.result.audit_token },
              })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnSubmitFormRequest$ = this.actions$.pipe(
    ofType(FormsActions.FormsSubmitResponsesRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.formsService.submitAuditResponses(
          requestDetails.formToken,
          requestDetails.answers,
          requestDetails.positiveResponse,
          requestDetails.userId
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(
              FormsActions.FormsSubmitResponsesReceiveAction({
                payload: { positiveResponse: requestDetails.positiveResponse },
              })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnSubmitFormReceive$ = this.actions$.pipe(
    ofType(FormsActions.FormsSubmitResponsesReceiveAction),
    map((action) => {
      if (action.payload.positiveResponse === '0') {
        this.router.navigate(['/screening/success']);
      } else {
        this.router.navigate(['/screening/fail']);
      }
    })
  );
}
