import { createSelector } from '@ngrx/store';
import { ScheduleState2Interface, selectAll } from './schedule.state';
import { getSchedule2State } from '../store/store.selectors';

export const getSchedule = createSelector(
  getSchedule2State,
  (state: ScheduleState2Interface) => selectAll(state)
);

export const getLastPublishedDate = createSelector(
  getSchedule2State,
  (state: ScheduleState2Interface) => state.published_at
);

export const getScheduleDetails = createSelector(
  getSchedule2State,
  (state: ScheduleState2Interface) => state.scheduleDetails
);

export const getScheduledDates = createSelector(
  getSchedule2State,
  (state: ScheduleState2Interface) => state.scheduledDates
);
