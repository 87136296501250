import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TimeEntryModel } from '../backend/time-entry/model/time-entry.model';

export interface TimeEntryStateInterface extends EntityState<TimeEntryModel> {
  // additional entity state properties
  activeRangeStartDate: string;
  activeRangeEndDate: string;
  activeDate: string;
  editedTimeEntry: TimeEntryModel;
  pendingTimeEntries: TimeEntryModel[];
  images: { image: string, base64Url: string, id: string } [];
  nonPedigreeTimes: any[];
  ptoTimes: any[];
}

export const adapterTimeEntry: EntityAdapter<TimeEntryModel> = createEntityAdapter<
  TimeEntryModel
>();

export const initTimeEntryState: TimeEntryStateInterface = adapterTimeEntry.getInitialState(
  {
    activeDate: null,
    activeRangeStartDate: null,
    activeRangeEndDate: null,
    editedTimeEntry: null,
    pendingTimeEntries: null,
    images: [],
    nonPedigreeTimes: [],
    ptoTimes: []
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterTimeEntry.getSelectors();
