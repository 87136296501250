import * as ErrorActions from './error.actions';
import { ErrorStateInterface, initErrorState } from './error.state';

export function ErrorReducer(
  state = initErrorState,
  action
): ErrorStateInterface {
  switch (action.type) {
    case ErrorActions.ErrorReceiveAction.type: {
      const payload = action.payload;

      return {
        ...state,
        errorMessage: payload,
        timestamp: Date.now(),
      };
    }

    default:
      return state;
  }
}
