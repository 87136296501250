import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ScheduleActions from './schedule.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as LocationSelectors from './schedule.selectors';
import { ScheduledJobModel } from '../backend/schedule/model/scheduled-job.model';

@Injectable()
export class ScheduleStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public requestSchedule(reqData: { userId: string, startDate: string, endDate: string, division?: string }) {
    this.store.dispatch(ScheduleActions.RequestScheduleAction({payload: reqData}));
  }

  public requestScheduleDetails(reqData: { jobScheduleId: string }) {
    this.store.dispatch(ScheduleActions.RequestScheduleDetailsAction({payload: reqData}));
  }

  public clearScheduleDetails() {
    this.store.dispatch(ScheduleActions.ClearScheduleDetailsAction());
  }

  public getSchedule(): Observable<ScheduledJobModel[]> {
    return this.store.pipe(
      select((state) => LocationSelectors.getSchedule(state))
    );
  }

  public getLastPublishedDate(): Observable<string> {
    return this.store.pipe(
      select((state) => LocationSelectors.getLastPublishedDate(state))
    );
  }

  public getScheduledDates(): Observable<any[]> {
    return this.store.pipe(
      select((state) => LocationSelectors.getScheduledDates(state))
    );
  }

  public getScheduleDetails(): Observable<any> {
    return this.store.pipe(
      select((state) => LocationSelectors.getScheduleDetails(state))
    );
  }
}
