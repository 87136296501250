import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as PedigreeActions from './pedigree.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import { PedigreeTimeModel } from '../backend/pedigree/model/pedigree-time.model';
import * as PedigreeSelectors from './pedigree.selectors';

@Injectable()
export class PedigreeStoreService {
  constructor(private store: Store<SelectAppState>) {}
  public requestPedigreeEntries(
    startDate: string,
    endDate: string,
    userId: string
  ) {
    this.store.dispatch(
      PedigreeActions.PedigreeEntriesRequestAction({
        payload: { startDate, endDate, userId },
      })
    );
  }
  public getAllPedigreeEntries(): Observable<PedigreeTimeModel[]> {
    return this.store.pipe(
      select((state) => PedigreeSelectors.getAllPedigreeEntries(state))
    );
  }
}
