import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduleBackend } from './schedule.backend';

@Injectable()
export class ScheduleService extends BaseService {
  constructor(private scheduleBackend: ScheduleBackend) {
    super();
  }

  getSchedule(reqData: { userId: string, startDate: string, endDate: string, division?: string }): Observable<any> {
    return this.scheduleBackend
      .fetchSchedule(reqData)
      .pipe(map((response) => response.body));
  }

  getScheduleDetails(reqData: { jobScheduleId: string }): Observable<any> {
    return this.scheduleBackend
      .fetchScheduleDetails(reqData)
      .pipe(map((response) => response.body));
  }

  getSchedule2(reqData: { userId: string, startDate: string, endDate: string, division?: string[], jobId?: string }): Observable<any> {
    return this.scheduleBackend
      .fetchSchedule2(reqData)
      .pipe(map((response) => response.body));
  }

  getSchedule2Details(reqData: { jobScheduleId: string }): Observable<any> {
    return this.scheduleBackend
      .fetchSchedule2Details(reqData)
      .pipe(map((response) => response.body));
  }
}
