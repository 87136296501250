import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SearchableItemsListPage } from './modals/searchable-items-list/searchable-items-list.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [SearchableItemsListPage],
})
export class SharedModule {}
