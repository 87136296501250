import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as JobActions from './job.actions';
import * as ErrorActions from '../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../backend/alert-service/alert.service';
import { TimeEntryService } from '../backend/time-entry/time-entry.service';
import { JobService } from '../backend/job/job.service';

@Injectable()
export class JobEffects {
  constructor(
    private actions$: Actions,
    private timeEntryService: TimeEntryService,
    private jobService: JobService,
    private router: Router,
    private alertService: AlertService
  ) {}

  @Effect()
  OnGetAllJobsRequest$ = this.actions$.pipe(
    ofType(JobActions.RequestAllJobsAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(this.jobService.getAllJobs(requestDetails.userId)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(JobActions.ReceiveAllJobsAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnGetJobDetailsRequest$ = this.actions$.pipe(
    ofType(JobActions.RequestJobDetailsAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.jobService.getJobDetails(
          requestDetails.userId,
          requestDetails.jobId
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(JobActions.ReceiveJobDetailsAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  // @Effect({ dispatch: false })
  // OnLoginReceive$ = this.actions$.pipe(
  //   ofType(AuthSessionActions.LoginReceiveAction),
  //   map(() => {
  //     this.router.navigate(['/dashboard']);
  //   })
  // );

  // @Effect()
  // OnForgotPasswordRequest$ = this.actions$.pipe(
  //   ofType(AuthSessionActions.ForgotPasswordRequestAction),
  //   map((action) => action.payload.email),
  //   throttleTime(2000),
  //   switchMap((email: string) =>
  //     from(this.authService.forgotPassword(email)).pipe(
  //       mergeMap(
  //         (data: any) =>
  //           iif(
  //             () => !!data && data.status !== 'error',
  //             of(AuthSessionActions.ForgotPasswordReceiveAction()),
  //             of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
  //           )
  //         // TODO update endpoint to return error code so it can be caught automatically here
  //         // catchError((data) =>
  //         //   of(
  //         //     ErrorActions.ErrorReceiveAction({
  //         //       payload: {
  //         //         message: data.error.errors.message,
  //         //         type: ErrorType.token,
  //         //       },
  //         //     })
  //         //   )
  //         // )
  //       )
  //     )
  //   )
  // );

  // @Effect({ dispatch: false })
  // OnForgotPasswordReceive$ = this.actions$.pipe(
  //   ofType(AuthSessionActions.ForgotPasswordReceiveAction),
  //   map(() => {
  //     this.alertService.presentAlert('Success', 'Please check your email.', [
  //       'Close',
  //     ]);
  //   })
  // );
}
