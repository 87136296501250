import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TimeEntryBackend } from './time-entry.backend';
import { TimeEntryModel } from './model/time-entry.model';

@Injectable()
export class TimeEntryService extends BaseService {
  timeEntries$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private timeEntryBackend: TimeEntryBackend) {
    super();
  }

  getAllTimeEntries(
    startDate: string,
    endDate: string,
    employeeId: string
  ): Observable<any> {
    return this.timeEntryBackend
      .fetchTimeEntries(startDate, endDate, employeeId)
      .pipe(map((response) => response.body));
  }

  updateTimeEntries(
    timeEntries: TimeEntryModel[],
    status: string,
    time_difference: string | null
  ): Observable<any> {
    return this.timeEntryBackend
      .fetchUpdateTimeEntries(timeEntries, status, time_difference)
      .pipe(
        map((response) => response.body),
        tap((x) => console.log(x))
      );
  }

  deleteTimeEntry(timeEntryId: string): Observable<any> {
    return this.timeEntryBackend.fetchDeleteTimeEntry(timeEntryId).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }

  addTimeEntryPhotos(base64Url: string): Observable<any> {
    return this.timeEntryBackend.fetchAddTimeEntryPhotos(base64Url).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }

  deleteTimeEntryPhotos(imagesNames: string[]): Observable<any> {
    return this.timeEntryBackend.fetchDeleteTimeEntryPhoto(imagesNames).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }

  getTimeEntryPhotos(
    imagesData: { id: string; time_entry_photo: string }[]
  ): Observable<any> {
    return this.timeEntryBackend.fetchTimeEntryPhotos(imagesData).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }


  addNonPedigreeTime(data: { user_id, formattedTimeIntervals, deletedTimeIntervalIds }): Observable<any> {
    return this.timeEntryBackend.fetchAddNonPedigreeTime(data).pipe(
            map((response) => response.body),
            tap((x) => console.log(x))
    );
  }

  getNonPedigreeTime(data: { user_id: string, start_hours: string, end_hours: string}): Observable<any> {
    return this.timeEntryBackend.fetchNonPedigreeTime(data).pipe(
            map((response) => response.body),
            tap((x) => console.log(x))
    );
  }

  getPTOTime(data: { user_id: string, start_date: string, end_date: string}): Observable<any> {
    return this.timeEntryBackend.fetchPTOTime(data).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }
}
