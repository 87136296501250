import * as FuelLogsActions from './fuel-logs.actions';
import {
  adapterFuelLogs,
  FuelLogsStateInterface, initFuelLogsState
} from './fuel-logs.state';

export function FuelLogReducer(
  state = initFuelLogsState,
  action
): FuelLogsStateInterface {
  switch (action.type) {
    case FuelLogsActions.RequestAction.type: {
      return adapterFuelLogs.removeAll(state);
    }

    case FuelLogsActions.ReceiveAction.type: {
      const payload = action.payload.data;
      return adapterFuelLogs.upsertMany(payload, { ...state, ...{
        images: []
        } });
    }

    case FuelLogsActions.LocationsReceiveAction.type: {
      const payload = action.payload;
      return  {...state, ...{
          pits: payload.pits,
          vendors: payload.vendors
        }
      };
    }

    case FuelLogsActions.FuelTypeReceiveAction.type: {
      const payload = action.payload.data;
      return  {...state, ...{
          fuelTypes: payload
        }
      };
    }

    case FuelLogsActions.ReceiveDeleteAction.type: {
      const payload = action.payload;
      return adapterFuelLogs.removeOne(payload, { ...state });
    }

    case FuelLogsActions.ReceiveAddPhotoAction.type: {
      const payload = action.payload;
      return {
        ...state,
        images: [
          ...state.images,
          ...[
            {
              image: payload.imageName,
              base64Url: payload.base64Url,
              id: null,
            },
          ],
        ],
      };
    }

    default:
      return state;
  }
}
