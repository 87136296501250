import { createAction, props } from '@ngrx/store';
import { PlantModel } from '../backend/plant/model/plant.model';

export const RequestActivePlantsAction = createAction('[Plant] Request All');

export const ReceiveActivePlantsAction = createAction(
  '[Plant] Receive All',
  props<{ payload: { Plants: PlantModel[] } }>()
);

export const RequestMSHAInspectionsAction = createAction('[Plant] Request MSHA');

export const ReceiveMSHAInspectionsAction = createAction(
  '[Plant] Receive MSHA',
  props<{ payload: { Inspections: any[] } }>()
);

export const SubmitInspectionRequestAction = createAction(
  '[Plant] Submit Inspection Request',
  props<{
    payload: {
      answers: string;
      userId: string;
      plantId: string;
    };
  }>()
);

export const SubmitInspectionReceiveAction = createAction(
  '[Plant] Submit Inspection Receive'
);
