import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-searchable-items-list',
  templateUrl: './searchable-items-list.page.html',
  styleUrls: ['./searchable-items-list.page.scss'],
})
export class SearchableItemsListPage implements OnInit, OnDestroy {
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  filteredItems: { id: string; name: string }[] = [];
  isPumpLocationSearch = false;
  pits: { id: string; name: string }[] = [];
  vendors: { id: string; name: string }[] = [];


  @Input()
  title: string;

  @Input()
  items: { id: string; name: string }[];

  @Input()
  data: any;

  @ViewChild('searchInput') searchInput: IonSearchbar;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
    if (!this.data?.isPumpLocationSearch) {
      this.filteredItems = [...this.items];
    } else {
      this.isPumpLocationSearch = true;
      this.pits = this.items.filter((i: any) => i.type === 'PIT');
      this.vendors = this.items.filter((i: any) => i.type === 'VENDOR');
    }
  }

  searchChanged(event) {
    if (!this.isPumpLocationSearch) {
      this.filteredItems = this.items.filter((item) => {
        const itemName = item.name.toLowerCase();
        return itemName.includes(event.detail.value.toLowerCase());
      });

      if (event.detail.value === '') {
        this.filteredItems = [...this.items];
      }
    } else {
      this.pits = this.items.filter((i: any) => i.type === 'PIT').filter((item: any) => {
        const itemName = item.name.toLowerCase();
        return itemName.includes(event.detail.value.toLowerCase());
      });
      this.vendors = this.items.filter((i: any) => i.type === 'VENDOR').filter((item: any) => {
        const itemName = item.name.toLowerCase();
        return itemName.includes(event.detail.value.toLowerCase());
      });
      if (event.detail.value === '') {
        this.pits = this.items.filter((i: any) => i.type === 'PIT');
        this.vendors = this.items.filter((i: any) => i.type === 'VENDOR');
      }
    }
  }

  closeModal(item?) {
    // extra data passed for departments/jobs
    let selectedItem = { ...item };

    this.modalController.dismiss(
      item
        ? {
            selectedItemId: selectedItem.id,
            selectedItem,
          }
        : { selectedItemId: undefined, selectedItem: {} }
    );
  }

  // closeModal(selectedItemId?: string, itemIndex?: number) {
  //   // extra data passed for departments/jobs
  //   let selectedItem = {};
  //   if (itemIndex) {
  //     selectedItem = this.filteredItems[itemIndex];
  //   }

  //   if (itemIndex === 0) {
  //     selectedItem = this.firstItem;
  //   }

  //   this.modalController.dismiss({ selectedItemId, selectedItem });
  // }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
