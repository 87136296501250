import * as JobActions from './job.actions';
import { adapterJob, initJobState, JobStateInterface } from './job.state';

export function JobReducer(state = initJobState, action): JobStateInterface {
  switch (action.type) {
    case JobActions.ReceiveAllJobsAction.type: {
      const payload = action.payload.result;
      console.log(payload);
      return adapterJob.upsertMany(payload, { ...state }); // add or update time jobs
    }

    case JobActions.ReceiveJobDetailsAction.type: {
      const payload = action.payload.result;
      console.log(payload);
      return {
        ...state,
        selectedJobCostCodes: payload.cost_codes,
        selectedJobEquipment: payload.equipment,
        selectedJobMaterials: payload.material,
      };
    }
    default:
      return state;
  }
}
