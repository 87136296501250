import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { JobModel } from '../backend/job/model/job.model';

export interface JobStateInterface extends EntityState<JobModel> {
  // additional entity state properties
  selectedJobMaterials: { id: string; name: string }[];
  selectedJobEquipment: { id: string; name: string }[];
  selectedJobCostCodes: { id: string; name: string }[];
}

export const adapterJob: EntityAdapter<JobModel> = createEntityAdapter<
  JobModel
>();

export const initJobState: JobStateInterface = adapterJob.getInitialState({
  selectedJobMaterials: [],
  selectedJobEquipment: [],
  selectedJobCostCodes: [],
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterJob.getSelectors();
