import { createAction, props } from '@ngrx/store';
import { UserBackendModel } from '../../backend/auth/model/user-backend.model';
import { UserCredentials } from '../../backend/auth/model/user-creds.model';

export const LoginRequestAction = createAction(
  '[Auth] Login Request',
  props<{ payload: UserCredentials }>()
);

export const ChangeTemporaryPasswordRequestAction = createAction(
  '[Auth] Temporary Password Change Request',
  props<{ payload: { user_id: string; password: string; jwtToken: string } }>()
);

export const LoginReceiveAction = createAction(
  '[Auth] Login Receive',
  props<{ payload: UserBackendModel }>()
);

export const LogoutRequestAction = createAction('[Auth] Logout Request');

export const LogoutReceiveAction = createAction('[Auth] Logout Receive');

export const ForgotPasswordRequestAction = createAction(
  '[Auth] Forgot Password Request',
  props<{ payload: { email: string } }>()
);

export const ForgotPasswordReceiveAction = createAction(
  '[Auth] Forgot Password Receive'
);

export const PasswordResetRequestAction = createAction(
    '[Auth] Password Reset Request',
    props<{ payload: { username: string, password: string, year: string } }>()
);

export const PasswordResetReceiveAction = createAction(
    '[Auth] Password Reset Receive'
);
