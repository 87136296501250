import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as TimeEntryActions from './time-entry.actions';
import * as ErrorActions from '../error/error.actions';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../backend/alert-service/alert.service';
import { TimeEntryService } from '../backend/time-entry/time-entry.service';
import { TimeEntryStoreService } from './time-entry.service';
import { select, Store } from '@ngrx/store';
import { SelectAppState } from '../store/store.state';
import { getActiveEndDate, getActiveStartDate } from './time-entry.selectors';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ToastService } from '../backend/toast-service/toast.service';

@Injectable()
export class TimeEntryEffects {
  constructor(
    private actions$: Actions,
    private timeEntryService: TimeEntryService,
    private router: Router,
    private alertService: AlertService,
    private timeEntryStoreService: TimeEntryStoreService,
    private store: Store<SelectAppState>,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {}

  @Effect()
  OnTimeEntriesRequest$ = this.actions$.pipe(
    ofType(TimeEntryActions.TimeEntriesRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.timeEntryService.getAllTimeEntries(
          requestDetails.startDate,
          requestDetails.endDate,
          requestDetails.employeeId
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(TimeEntryActions.TimeEntriesReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnTimeEntriesUpdateRequest$ = this.actions$.pipe(
    ofType(TimeEntryActions.UpdateTimeEntriesRequestAction),
    filter((action) => !!action.payload),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.timeEntryService
          .updateTimeEntries(
            requestDetails.timeEntries,
            requestDetails.status,
            requestDetails.time_difference
          )
          .pipe(
            mergeMap((data: any) =>
              iif(
                () => !!data && data.status !== 'error',
                of(
                  TimeEntryActions.UpdateTimeEntriesReceiveAction({
                    payload: {
                      timeEntries: requestDetails.timeEntries,
                      status: requestDetails.status,
                      updateType: requestDetails.timeEntries[0].id
                        ? 'edit'
                        : 'new',
                    },
                  })
                ),
                of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
              )
            )
          )
      )
    )
  );

  @Effect({ dispatch: false })
  OnTimeEntriesUpdateReceive$ = this.actions$.pipe(
    ofType(TimeEntryActions.UpdateTimeEntriesReceiveAction),
    map((action) => action.payload),
    withLatestFrom(
      this.store.pipe(select((state) => getActiveStartDate(state))),
      this.store.pipe(select((state) => getActiveEndDate(state)))
    ),
    map((data) => {
      console.log('Effect : ', data);
      let message =
        data[0].updateType == 'new'
          ? 'New entry added successfully.'
          : 'Entry updated successfully.';
      console.log('message : ', message);
      this.toastService.presentToast(message, 'success');
      if (data[0].status === '3') {
        this.router.navigate(['dashboard']);
      } else {
        const id = this.localStorageService.getItem('id');
        // Necessary otherwise get time entries doesn't pull the latest, request is sent too fast
        setTimeout(() => {
          this.timeEntryStoreService.requestTimeEntries(data[1], data[2], id);
        }, 2500);
      }
    })
  );

  @Effect()
  OnDeleteTimeEntryRequest$ = this.actions$.pipe(
    ofType(TimeEntryActions.DeleteTimeEntryRequestAction),
    filter((action) => !!action.payload),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.timeEntryService.deleteTimeEntry(requestDetails.timeEntryId).pipe(
          mergeMap((data: any) =>
            iif(
              () => !!data && data.status !== 'error',
              of(
                TimeEntryActions.DeleteTimeEntryReceiveAction({
                  payload: { timeEntryId: requestDetails.timeEntryId },
                })
              ),
              of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
            )
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  onDeleteTimeEntryReceive$ = this.actions$.pipe(
    ofType(TimeEntryActions.DeleteTimeEntryReceiveAction),
    map(() => {
      this.toastService.presentToast('Entry deleted successfully.', 'success');
    })
  );

  @Effect()
  OnTimeEntryPhotosRequest$ = this.actions$.pipe(
    ofType(TimeEntryActions.TimeEntryPhotosRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(this.timeEntryService.getTimeEntryPhotos(requestDetails)).pipe(
        mergeMap((data: any) => {
          return iif(
            () => !!data && data.status !== 'error',
            of(
              TimeEntryActions.TimeEntryPhotosReceiveAction({ payload: data })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          );
        })
      )
    )
  );

  @Effect()
  OnAddTimeEntryPhotoRequest$ = this.actions$.pipe(
    ofType(TimeEntryActions.AddTimeEntryPhotosRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) => {
      return from(
        this.timeEntryService.addTimeEntryPhotos(requestDetails.base64Url)
      ).pipe(
        mergeMap((response: any) =>
          iif(
            () => !!response && response.status !== 'error',
            of(
              TimeEntryActions.AddTimeEntryPhotosReceiveAction({
                payload: {
                  imageName: response.image_name,
                  base64Url: requestDetails.base64Url,
                },
              })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: response.message }))
          )
        )
      );
    })
  );

  @Effect()
  OnDeleteTimeEntryPhotoRequest$ = this.actions$.pipe(
    ofType(TimeEntryActions.DeleteTimeEntryPhotosRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.timeEntryService.deleteTimeEntryPhotos(requestDetails.imagesNames)
      ).pipe(
        mergeMap((response: any) =>
          iif(
            () => !!response && response.status !== 'error',
            of(
              TimeEntryActions.DeleteTimeEntryPhotosReceiveAction({
                payload: { imagesNames: requestDetails.imagesNames },
              })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: response.message }))
          )
        )
      )
    )
  );

    @Effect()
    OnAddNonPedigreeTimeRequest$ = this.actions$.pipe(
        ofType(TimeEntryActions.AddNonPedigreeTimeRequestAction),
        map((action) => action.payload),
        throttleTime(2000),
        switchMap((requestDetails: any) =>
            from(
                this.timeEntryService.addNonPedigreeTime(
                    {
                        user_id: requestDetails.user_id,
                        formattedTimeIntervals: requestDetails.formattedTimeIntervals,
                        deletedTimeIntervalIds: requestDetails.deletedTimeIntervalIds
                    }
                )
            ).pipe(
                tap(() => {
                    setTimeout(() => {
                        this.router.navigate(['time-entry']);
                    }, 1000);
                }),
                mergeMap((data: any) =>
                    iif(
                        () => !!data && data.status !== 'error',
                        of(TimeEntryActions.AddNonPedigreeTimeReceiveAction({ payload: data })),
                        of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
                    )
                )
            )
        )
    );

    @Effect({dispatch: false})
    OnAddNonPedigreeTimeReceive$ = this.actions$.pipe(
        ofType(TimeEntryActions.AddNonPedigreeTimeReceiveAction),
        tap(() => {
            setTimeout(() => {
                this.router.navigate(['time-entry']);
            }, 1000);
        }),
    );

    @Effect()
    OnGetNonPedigreeTimeRequest$ = this.actions$.pipe(
        ofType(TimeEntryActions.GetNonPedigreeTimeRequestAction),
        map((action) => action.payload),
        throttleTime(2000),
        switchMap((requestDetails: any) =>
            from(
                this.timeEntryService.getNonPedigreeTime(
                    {
                        user_id: requestDetails.user_id,
                        start_hours: requestDetails.start_hours,
                        end_hours: requestDetails.end_hours,
                    }
                )
            ).pipe(
                mergeMap((data: any) =>
                    iif(
                        () => !!data && data.status !== 'error',
                        of(TimeEntryActions.GetNonPedigreeTimeReceiveAction({ payload: data })),
                        of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
                    )
                )
            )
        )
    );

  @Effect()
  OnPTORequest = this.actions$.pipe(
    ofType(TimeEntryActions.PTORequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.timeEntryService.getPTOTime(
          {
            user_id: requestDetails.user_id,
            start_date: requestDetails.start_date,
            end_date: requestDetails.end_date
          }
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(TimeEntryActions.PTOReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        ),
        catchError((e) => of(ErrorActions.ErrorReceiveAction({ payload: e.error.error })))
      )
    )
  );
}
