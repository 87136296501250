import { createAction, props } from '@ngrx/store';
import { JobModel } from '../backend/job/model/job.model';

export const RequestAllJobsAction = createAction(
  '[Job] Request All Jobs',
  props<{ payload: { userId: string } }>()
);

export const ReceiveAllJobsAction = createAction(
  '[Job] Receive All Jobs',
  props<{ payload: { jobs: JobModel[] } }>()
);

export const RequestJobDetailsAction = createAction(
  '[Job] Request Job Details',
  props<{ payload: { userId: string; jobId: string } }>()
);

export const ReceiveJobDetailsAction = createAction(
  '[Job] Receive Job Details',
  props<{ payload: any }>()
);
