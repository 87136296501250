import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';


@Injectable()
export class PlantBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    'content-type': 'application/json',
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchActivePlants(
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/plant/active-list`;
    return this.http.get<any>(url, this.getOptions());
  }

  fetchMSHAInspections(
  ): Observable<HttpResponse<any>> {
    const data = {
      formId: 21
    };
    const url = `${this.API_ROOT}/form/list-responses`;
    return this.http.post<any>(url, data, this.getOptions());
  }

  fetchSubmitInspection(
    answers: any,
    user_id: string,
    plant_id: string,
  ): Observable<HttpResponse<any>> {
    const data = {
      answers,
      user_id,
      plant_id,
      time_stamp: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    const url = `${this.API_ROOT}/form/submit-msha-inspection`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
