import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ErrorSelectors from './error.selectors';
import { Observable } from 'rxjs';
import { SelectAppState } from '../store/store.state';
import { ToastService } from '../backend/toast-service/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorStoreService {
  constructor(
    private store: Store<SelectAppState>,
    private toastService : ToastService
  ) {}

  public displayError(errorMessage: string) {
    if (errorMessage === 'Orange DB Id is missing in Our DB') {
      errorMessage = 'Orange HRM - data not found.';
    }
    // this.alertService.presentAlert('Error', errorMessage, ['Close']);
    this.toastService.presentToast(errorMessage, "danger");
  }

  public getErrorMessage(): Observable<string> {
    return this.store.pipe(
      select((state) => ErrorSelectors.getLatestErrorMessage(state))
    );
  }

  public getErrorTimeStamp(): Observable<number> {
    return this.store.pipe(
      select((state) => ErrorSelectors.getLatestErrorTimeStamp(state))
    );
  }
}
