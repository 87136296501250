import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { SearchableItemsListPage } from 'src/app/shared/modals/searchable-items-list/searchable-items-list.page';
import {AddEditNoteComponent} from './add-edit-note/add-edit-note.component';

@Component({
  selector: 'app-notes-manager',
  templateUrl: './notes-manager.component.html',
  styleUrls: ['./notes-manager.component.scss'],
})
export class NotesManagerComponent implements OnInit {
  notesCopy: any[] = [];
  isEditableCopy = true;
  activeUserId = JSON.parse(localStorage.getItem('user_data')).id;

  @Input() title: string;
  @Input() selectedItems: any[] = [];
  @Input() notes: any[] = [];
  @Input() removed: string[] = [];
  @Input() isEditable: boolean = true;
  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit() {}

  async addEditNote(notesIndex?: number) {
    if (!this.isEditableCopy) {
      return;
    }

    if (notesIndex > -1 && this.activeUserId !== this.notesCopy[notesIndex]?.created_by) {
      return;
    }

    let selectedNote;
    if (notesIndex > -1) {
      selectedNote = this.notesCopy[notesIndex];
    }

    try {
      const modal = await this.modalController.create({
        component: AddEditNoteComponent,
        componentProps: {
          selectedNote,
          index: notesIndex ? null : this.notesCopy.length
        },
      });

      await modal.present();
      modal.onDidDismiss().then((data) => {
        if (data.data?.note) {

          // Handle editing existing note
          if (data.data.note.id) {
            this.notesCopy = this.notesCopy.filter(note => note.id !== data.data.note.id);
            this.notesCopy = [...this.notesCopy, data.data.note];
            return;
          }

          // Handle adding new note
          if (data.data.note.new) {
            const newNote = {...data.data.note, ...{ new: false }};
            this.notesCopy = [...this.notesCopy, newNote];
            return;
          }

          // Handle editing new note
          if (!data.data.note.new) {
            this.notesCopy[data.data.note.index] = {...this.notesCopy[data.data.note.index], ... {note: data.data.note.note}};
            return;
          }
          console.log(this.notesCopy);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  ngAfterContentInit() {
    this.notesCopy = this.notes;
    this.isEditableCopy = this.isEditable;
    console.log(this.notesCopy);
  }

  onRemoveRow(index) {
    const removedNote = this.notesCopy[index];

    if (this.activeUserId !== removedNote.created_by) {
      return;
    }

    if (removedNote?.id) {
      this.removed.push(removedNote.id);
    }

    this.notesCopy = this.notesCopy.filter(note => note !== removedNote);
  }

  close() {
    this.modalController.dismiss();
  }

  async submitItems() {
    this.modalController.dismiss({
      notes: this.notesCopy,
      removed: this.removed,
    });
  }
}
