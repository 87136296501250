import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as DepartmentActions from './department.actions';
import * as ErrorActions from '../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { DepartmentService } from '../backend/department/department.service';

@Injectable()
export class DepartmentEffects {
  constructor(
    private actions$: Actions,
    private departmentService: DepartmentService
  ) {}

  @Effect()
  OnGetAllDepartmentsRequest$ = this.actions$.pipe(
    ofType(DepartmentActions.RequestAllDepartmentsAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.departmentService.getAllDepartments(null)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(
              DepartmentActions.ReceiveAllDepartmentsAction({ payload: data })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );
}
