import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as TimeEntryActions from '../time-entry/time-entry.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import { TimeEntryModel } from '../backend/time-entry/model/time-entry.model';
import * as TimeEntrySelectors from '../time-entry/time-entry.selectors';

@Injectable()
export class TimeEntryStoreService {
  constructor(private store: Store<SelectAppState>) {}
  public requestTimeEntries(
    startDate: string,
    endDate: string,
    employeeId: string
  ) {
    this.store.dispatch(
      TimeEntryActions.TimeEntriesRequestAction({
        payload: { startDate, endDate, employeeId },
      })
    );
  }
  public getAllTimeEntries(): Observable<TimeEntryModel[]> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getAllTimeEntries(state))
    );
  }
  public getActiveTimeEntries(): Observable<TimeEntryModel[]> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getActiveEntries(state))
    );
  }
  public getActiveTimeEntriesHours(): Observable<any> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getWeeklyTotalHours(state))
    );
  }
  public getTimeEntriesByDate(
    selectedDate: string
  ): Observable<TimeEntryModel[]> {
    return this.store.pipe(
      select((state) =>
        TimeEntrySelectors.getTimeEntriesByDate(state, selectedDate)
      )
    );
  }
  public getActiveDate(): Observable<string> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getActiveDate(state))
    );
  }
  public setActiveDate(activeDate: string) {
    this.store.dispatch(
      TimeEntryActions.SetActiveDateAction({ payload: { activeDate } })
    );
  }
  public clearActiveDate(activeDate: string) {
    this.store.dispatch(TimeEntryActions.ClearActiveDateAction());
  }
  public getEditedTimeEntry(): Observable<TimeEntryModel> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getEditedTimeEntry(state))
    );
  }
  public setEditedTimeEntry(timeEntry: TimeEntryModel) {
    this.store.dispatch(
      TimeEntryActions.SetEditedTimeEntryAction({ payload: { timeEntry } })
    );
  }
  public clearEditedTimeEntry() {
    this.store.dispatch(TimeEntryActions.ClearEditedTimeEntryAction());
  }
  public getPendingTimeEntries(): Observable<TimeEntryModel[]> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getPendingTimeEntries(state))
    );
  }
  public setPendingTimeEntries(timeEntries: TimeEntryModel[]) {
    this.store.dispatch(
      TimeEntryActions.SetPendingTimeEntriesAction({ payload: { timeEntries } })
    );
  }
  public clearPendingTimeEntries() {
    this.store.dispatch(TimeEntryActions.ClearPendingTimeEntriesAction());
  }
  public updateTimeEntries(
    timeEntries: TimeEntryModel[],
    status: string,
    time_difference: string | null = null
  ) {
    this.store.dispatch(
      TimeEntryActions.UpdateTimeEntriesRequestAction({
        payload: { timeEntries, status, time_difference },
      })
    );
  }
  public deleteTimeEntry(timeEntryId: string) {
    this.store.dispatch(
      TimeEntryActions.DeleteTimeEntryRequestAction({
        payload: { timeEntryId },
      })
    );
  }
  public addTimeEntryPhotos(base64Url: string) {
    this.store.dispatch(
        TimeEntryActions.AddTimeEntryPhotosRequestAction({
          payload: { base64Url },
        })
    );
  }
  public requestTimeEntryPhotos(timeEntries: { id: string, time_entry_photo: string }[]) {
    this.store.dispatch(
        TimeEntryActions.TimeEntryPhotosRequestAction({
          payload: timeEntries,
        })
    );
  }
  public getAllTimeEntriesPhotos(): Observable<any[]> {
    return this.store.pipe(
        select((state) => TimeEntrySelectors.getAllTimeEntriesPhotos(state))
    );
  }
  public deleteTimeEntryPhotos(imagesNames: string[]) {
    this.store.dispatch(
        TimeEntryActions.DeleteTimeEntryPhotosRequestAction({
          payload: { imagesNames },
        })
    );
  }
  public addNonPedigreeTime(
    user_id, formattedTimeIntervals, deletedTimeIntervalIds
  ) {
    this.store.dispatch(
        TimeEntryActions.AddNonPedigreeTimeRequestAction({
            payload: { user_id, formattedTimeIntervals, deletedTimeIntervalIds }})
    );
  }
    public requestNonPedigreeTime(
        user_id: string,
        start_hours: string,
        end_hours: string,
    ) {
        this.store.dispatch(
            TimeEntryActions.GetNonPedigreeTimeRequestAction({
                payload: { user_id, start_hours, end_hours }})
        );
    }
    public getNonPedigreeTimes(): Observable<any[]> {
        return this.store.pipe(
            select((state) => TimeEntrySelectors.getNonPedigreeTimes(state))
        );
    }

  public requestPTOTimes(
    user_id: string,
    start_date: string,
    end_date: string,
  ) {
    this.store.dispatch(
      TimeEntryActions.PTORequestAction({
        payload: { user_id, start_date, end_date }})
    );
  }

  public getPTOTimes(): Observable<any[]> {
    return this.store.pipe(
      select((state) => TimeEntrySelectors.getPTOTimes(state))
    );
  }
}
