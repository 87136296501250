import { NgModule } from '@angular/core';
import { AuthStoreService } from '../auth/auth.service';
import { TimeEntryStoreService } from '../time-entry/time-entry.service';
import { JobStoreService } from '../job/job.service';
import { FormsStoreService } from '../forms/forms.service';
import { PedigreeStoreService } from '../pedigree/pedigree.service';
import { DepartmentStoreService } from '../department/department.service';
import { LocationStoreService } from '../location/location.service';
import { ScheduleStoreService } from '../schedule/schedule.service';
import { PlantStoreService } from '../plant/plant.service';
import { FuelLogsStoreService } from '../fuel-logs/fuel-logs.service';
import { Schedule2StoreService } from '../schedule2/schedule.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [],
})
export class StoreServicesModule {
  static forRoot() {
    return {
      ngModule: StoreServicesModule,
      providers: [
        AuthStoreService,
        TimeEntryStoreService,
        JobStoreService,
        FormsStoreService,
        PedigreeStoreService,
        DepartmentStoreService,
        LocationStoreService,
        ScheduleStoreService,
        PlantStoreService,
        FuelLogsStoreService,
        Schedule2StoreService
      ],
    };
  }
}
