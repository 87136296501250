import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './shared/guards/login.guard';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginGuard],
    pathMatch: 'full',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'time-entry',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./time-entry/time-entry.module').then(
        (m) => m.TimeEntryPageModule
      ),
  },
  {
    path: 'job-approval',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./job-approval/job-approval.module').then(
        (m) => m.JobApprovalPageModule
      ),
  },
  {
    path: 'time-approval',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./time-approval/time-approval.module').then(
        (m) => m.TimeApprovalPageModule
      ),
  },
  {
    path: 'equipment',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./equipment/equipment.module').then((m) => m.EquipmentPageModule),
  },
  {
    path: 'materials',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./materials/materials.module').then((m) => m.MaterialsPageModule),
  },
  {
    path: 'notes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./notes/notes.module').then((m) => m.NotesPageModule),
  },
  {
    path: 'edit-time-entry',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./edit-time-entry/edit-time-entry.module').then(
        (m) => m.EditTimeEntryPageModule
      ),
  },
  {
    path: 'screening',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./covid-screening/covid-screening.module').then(
        (m) => m.CovidScreeningModule
      ),
  },
    {
        path: 'non-pedigree-time',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./non-pedigree-time/non-pedigree-time.module').then(
                (m) => m.NonPedigreeTimeModule
            ),
    },
  {
    path: 'schedule',
    canActivate: [AuthGuard],
    loadChildren: () => import('./schedule/schedule.module').then( m => m.SchedulePageModule)
  },
  {
    path: 'schedule-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./schedule-details/schedule-details.module').then( m => m.ScheduleDetailsPageModule)
  },
  {
    path: 'msha',
    loadChildren: () => import('./msha/msha.module').then( m => m.MshaPageModule)
  },
  {
    path: 'fuel-logs',
    loadChildren: () => import('./fuel-logs/fuel-logs.module').then( m => m.FuelLogsPageModule)
  },
  {
    path: 'edit-fuel-logs',
    loadChildren: () => import('./edit-fuel-logs/edit-fuel-logs.module').then( m => m.EditFuelLogsPageModule)
  },
  {
    path: 'schedule-two',
    loadChildren: () => import('./schedule-two/schedule-two.module').then( m => m.ScheduleTwoPageModule)
  },
  {
    path: 'schedule-details-two',
    loadChildren: () => import('./schedule-details-two/schedule-details-two.module').then( m => m.ScheduleDetailsTwoPageModule)
  },
  {
    path: 'schedule-three',
    loadChildren: () => import('./schedule-three/schedule-three.module').then( m => m.ScheduleThreePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
