import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule as RxStoreModule } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { RouterSerializer } from './store.utils';
import { StoreServicesModule } from './store-services.module';
import { APP_REDUCER_TOKEN, AppReducers, metaReducers } from './store.reducers';
import { AuthSessionEffects } from '../auth/auth-session/auth-session.effects';
import { ErrorEffects } from '../error/error.effects';
import { TimeEntryEffects } from '../time-entry/time-entry.effects';
import { JobEffects } from '../job/job.effects';
import { FormsEffects } from '../forms/forms.effects';
import { PedigreeEffects } from '../pedigree/pedigree.effects';
import { DepartmentEffects } from '../department/department.effects';
import { LocationEffects } from '../location/location.effects';
import { ScheduleEffects } from '../schedule/schedule.effects';
import { PlantEffects } from '../plant/plant.effects';
import { FuelLogsEffects } from '../fuel-logs/fuel-logs.effects';
import { Schedule2Effects } from '../schedule2/schedule.effects';

const EFFECTS = [
  AuthSessionEffects,
  ErrorEffects,
  TimeEntryEffects,
  JobEffects,
  FormsEffects,
  PedigreeEffects,
  DepartmentEffects,
  LocationEffects,
  ScheduleEffects,
  PlantEffects,
  FuelLogsEffects,
  Schedule2Effects
];

@NgModule({
  imports: [
    EffectsModule.forRoot(EFFECTS),
    RxStoreModule.forRoot(APP_REDUCER_TOKEN, { metaReducers }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
        })
      : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: RouterSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreServicesModule.forRoot(),
  ],
  providers: [{ provide: APP_REDUCER_TOKEN, useValue: AppReducers }],
})
export class StoreModule {}
