import { SelectAppState } from './store.state';

export const getAuthState = (state: SelectAppState) => state.auth;
export const getRouterState = (state: SelectAppState) => state.router;
export const getErrorState = (state: SelectAppState) => state.error;
export const getTimeEntriesState = (state: SelectAppState) => state.timeEntry;
export const getJobState = (state: SelectAppState) => state.job;
export const getFormsState = (state: SelectAppState) => state.forms;
export const getPedigreeState = (state: SelectAppState) => state.pedigree;
export const getDepartmentState = (state: SelectAppState) => state.department;
export const getLocationState = (state: SelectAppState) => state.location;
export const getScheduleState = (state: SelectAppState) => state.schedule;
export const getPlantState = (state: SelectAppState) => state.plant;
export const getFuelLogsState = (state: SelectAppState) => state.fuelLogs;
export const getSchedule2State = (state: SelectAppState) => state.schedule2;

