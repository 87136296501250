import { createSelector } from '@ngrx/store';
import { getErrorState } from '../store/store.selectors';
import { ErrorStateInterface } from './error.state';

export const getLatestErrorMessage = createSelector(
  getErrorState,
  (state: ErrorStateInterface) => state.errorMessage
);

export const getLatestErrorTimeStamp = createSelector(
  getErrorState,
  (state: ErrorStateInterface) => state.timestamp
);
