import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as PlantActions from './plant.actions';
import * as ErrorActions from '../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { PlantService } from '../backend/plant/plant.service';
import { ToastService } from '../backend/toast-service/toast.service';
import { PlantStoreService } from './plant.service';

@Injectable()
export class PlantEffects {
  constructor(
    private actions$: Actions,
    private plantService: PlantService
  ) {}

  @Effect()
  OnGetAllPlantsRequest$ = this.actions$.pipe(
    ofType(PlantActions.RequestActivePlantsAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.plantService.getActivePlants()).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(PlantActions.ReceiveActivePlantsAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnGetMSHAInspectionsRequest$ = this.actions$.pipe(
    ofType(PlantActions.RequestMSHAInspectionsAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.plantService.getPlantInspections()).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(PlantActions.ReceiveMSHAInspectionsAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnSubmitInspectionRequest$ = this.actions$.pipe(
    ofType(PlantActions.SubmitInspectionRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.plantService.submitInspection(
          requestDetails.answers,
          requestDetails.userId,
          requestDetails.plantId
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(
              PlantActions.RequestMSHAInspectionsAction()
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );
}
