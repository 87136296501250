import { createSelector } from '@ngrx/store';
import { FuelLogsStateInterface, selectAll } from './fuel-logs.state';
import { getFuelLogsState } from '../store/store.selectors';

export const getAllFuelLogs = createSelector(
  getFuelLogsState,
  (state: FuelLogsStateInterface) => selectAll(state)
);

export const getPits = createSelector(
  getFuelLogsState,
  (state: FuelLogsStateInterface) => state.pits
);

export const getVendors = createSelector(
  getFuelLogsState,
  (state: FuelLogsStateInterface) => state.vendors
);

export const getLocations = createSelector(
  getPits,
  getVendors,
  (pits, vendors) => {
    return { pits, vendors };
  }
);

export const getFuelTypes = createSelector(
  getFuelLogsState,
  (state: FuelLogsStateInterface) => state.fuelTypes
);

export const getAllPhotos = createSelector(
  getFuelLogsState,
  (state: FuelLogsStateInterface) => state.images
);
