import { createSelector } from '@ngrx/store';
import {
  getDepartmentState,
  getJobState,
  getTimeEntriesState,
} from '../store/store.selectors';
import { DepartmentStateInterface, selectAll } from './department.state';

export const getAllDepartments = createSelector(
  getDepartmentState,
  (state: DepartmentStateInterface) => selectAll(state)
);
