import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ScheduleBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchSchedule(reqData: { userId: string, startDate: string, endDate: string, division?: string }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/schedule/list-job`;
    const data = {
      user_id: reqData.userId,
      start_date: reqData.startDate,
      end_date: reqData.endDate,
      division: reqData.division
    };
    return this.http.post<any>(url, data, this.getOptions());
  }

  fetchScheduleDetails(reqData: { jobScheduleId: string }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/schedule/show-details`;
    const data = {
      job_schedule_id: reqData.jobScheduleId,
    };
    return this.http.post<any>(url, data, this.getOptions());
  }

  fetchSchedule2(reqData: { userId: string, startDate: string, endDate: string, division?: string[], jobId?: string }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/schedule-2/list-events`;
    const data = {
      user_id: reqData.userId,
      start_date: reqData.startDate,
      end_date: reqData.endDate,
      division: reqData.division,
      job_id: reqData.jobId === '' ? null : reqData.jobId
    };
    return this.http.post<any>(url, data, this.getOptions());
  }

  fetchSchedule2Departments(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/schedule-2/list-job-departmets`;

    return this.http.get<any>(url, this.getOptions());
  }

  // used to fetch live schedule events
  fetchSchedule2Equipment(reqData: { startDate: string, endDate: string, equipment_type?: string, equipment_id?: string }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/schedule-2/list-equipments`;
    const data = {
      start_date: reqData.startDate,
      end_date: reqData.endDate,
      equipment_type: reqData.equipment_type === '' ? null : reqData.equipment_type,
      equipment_id: reqData.equipment_id === '' ? null : reqData.equipment_id,
    };
    return this.http.post<any>(url, data, this.getOptions());
  }

  // used to fetch all equipment and equipment type for filters in equipment tab
  fetchSchedule2EquipmentList(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/equipment/list-for-schedule2`;
    return this.http.get<any>(url, this.getOptions());
  }



  fetchAllEquipment(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/equipment/lists`;
    return this.http.get<{ data: any[] }>(url, this.getOptions());
  }

  fetchSchedule2Details(reqData: { jobScheduleId: string }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/schedule-2/show-details`;
    const data = {
      event_id: reqData.jobScheduleId,
    };
    return this.http.post<any>(url, data, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
