import { createSelector } from '@ngrx/store';
import { ScheduleStateInterface, selectAll } from './schedule.state';
import { getScheduleState } from '../store/store.selectors';

export const getSchedule = createSelector(
  getScheduleState,
  (state: ScheduleStateInterface) => selectAll(state)
);

export const getLastPublishedDate = createSelector(
  getScheduleState,
  (state: ScheduleStateInterface) => state.published_at
);

export const getScheduleDetails = createSelector(
  getScheduleState,
  (state: ScheduleStateInterface) => state.scheduleDetails
);

export const getScheduledDates = createSelector(
  getScheduleState,
  (state: ScheduleStateInterface) => state.scheduledDates
);
