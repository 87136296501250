import { createAction, props } from '@ngrx/store';

export const RequestScheduleAction = createAction('[Schedule] Request',
  props<{ payload: { userId: string, startDate: string, endDate: string, division?: string } }>()
);

export const ReceiveScheduleAction = createAction(
  '[Schedule] Receive',
  props<{ payload: any }>()
);

export const RequestScheduleDetailsAction = createAction('[Schedule] Request Details',
  props<{ payload: { jobScheduleId: string } }>()
);

export const ReceiveScheduleDetailAction = createAction(
  '[Schedule] Receive Details',
  props<{ payload: any }>()
);

export const ClearScheduleDetailsAction = createAction('[Schedule] Clear Details',
);
