import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as PedigreeActions from './pedigree.actions';
import * as ErrorActions from '../error/error.actions';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../backend/alert-service/alert.service';
import { PedigreeService } from '../backend/pedigree/pedigree.service';

@Injectable()
export class PedigreeEffects {
  constructor(
    private actions$: Actions,
    private pedigreeService: PedigreeService,
    private router: Router,
    private alertService: AlertService
  ) {}

  @Effect()
  OnPedigreeEntriesRequest$ = this.actions$.pipe(
    ofType(PedigreeActions.PedigreeEntriesRequestAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) =>
      from(
        this.pedigreeService.getPedigreeHours(
          requestDetails.startDate,
          requestDetails.endDate,
          requestDetails.userId
        )
      ).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(PedigreeActions.PedigreeEntriesReceiveAction({ payload: data }))
            // of(ErrorActions.ErrorReceiveAction({ payload: 'No data found in Pedigree' }))
          )
        )
      )
    )
  );
}
