import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as FuelLogsActions from './fuel-logs.actions';
import * as ErrorActions from '../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { FuelLogsService } from '../backend/fuel-logs/fuel-logs.service';
import * as TimeEntryActions from '../time-entry/time-entry.actions';
import { logger } from 'codelyzer/util/logger';

@Injectable()
export class FuelLogsEffects {
  constructor(
    private actions$: Actions,
    private fuelLogsService: FuelLogsService
  ) {}

  @Effect({dispatch: false})
  OnAddUpdateRequest$ = this.actions$.pipe(
    ofType(FuelLogsActions.CreateRequestAction),
    throttleTime(2000),
    map((action) => action.payload),
    switchMap((reqData: any) =>
      from(this.fuelLogsService.addUpdate(reqData)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(FuelLogsActions.ReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnDeleteRequest$ = this.actions$.pipe(
    ofType(FuelLogsActions.RequestDeleteAction),
    map((action) => action.payload),
    switchMap((reqData: any) =>
      from(this.fuelLogsService.deleteFuelLog(reqData)).pipe(
        tap(x => console.log(x)),
        mergeMap((data: any) =>
          iif(
            () => !!data && data.message,
            of(FuelLogsActions.ReceiveDeleteAction({ payload: reqData })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnAddPhotoRequest$ = this.actions$.pipe(
    ofType(FuelLogsActions.RequestAddPhotoAction),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((requestDetails: any) => {
      return from(
        this.fuelLogsService.addPhoto(requestDetails.base64Url)
      ).pipe(
        mergeMap((response: any) =>
          iif(
            () => !!response && response.status !== 'error',
            of(
              FuelLogsActions.ReceiveAddPhotoAction({
                payload: {
                  imageName: response.image_name,
                  base64Url: requestDetails.base64Url,
                },
              })
            ),
            of(ErrorActions.ErrorReceiveAction({ payload: response.message }))
          )
        )
      );
    })
  );

  @Effect()
  OnLocationsRequest$ = this.actions$.pipe(
    ofType(FuelLogsActions.LocationsRequestAction),
    throttleTime(2000),
    switchMap((reqData: any) =>
      from(this.fuelLogsService.getPumpLocations()).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(FuelLogsActions.LocationsReceiveAction({ payload: data.result })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnFuelTypeRequest$ = this.actions$.pipe(
    ofType(FuelLogsActions.FuelTypeRequestAction),
    switchMap(() =>
      from(this.fuelLogsService.getFuelTypes()).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(FuelLogsActions.FuelTypeReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnRequest$ = this.actions$.pipe(
    ofType(FuelLogsActions.RequestAction),
    switchMap((reqData: any) =>
      from(this.fuelLogsService.getFuelLogs(reqData)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data.status !== 'error',
            of(FuelLogsActions.ReceiveAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );
}
