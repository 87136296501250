import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PedigreeTimeModel } from '../backend/pedigree/model/pedigree-time.model';

export interface PedigreeStateInterface
  extends EntityState<PedigreeTimeModel> {}

export const adapterPedigree: EntityAdapter<PedigreeTimeModel> = createEntityAdapter<
  PedigreeTimeModel
>();

export const initPedigreeState: PedigreeStateInterface = adapterPedigree.getInitialState(
  {}
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterPedigree.getSelectors();
