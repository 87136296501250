import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TimeEntryModel } from './model/time-entry.model';
import * as moment from 'moment';

@Injectable()
export class TimeEntryBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    'content-type': 'application/json',
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchAllLaborCodes() {
    const url = `${this.API_ROOT}/labor-code/lists`;
    return this.http.get<{ data: any[] }>(url, this.getOptions());
  }

  fetchAllMaterial(id) {
    const url = `${this.API_ROOT}/material/lists`;
    return this.http.get<{ data: any[] }>(url, this.getOptions());
  }

  fetchAllEquipment() {
    const url = `${this.API_ROOT}/equipment/lists`;
    return this.http.get<{ data: any[] }>(url, this.getOptions());
  }

  fetchAllTows() {
    const url = `${this.API_ROOT}/tow/lists`;
    return this.http.get<{ data: any[] }>(url, this.getOptions());
  }

  fetchHourTypes(id, department_code = null) {
    const url = `${this.API_ROOT}/hours-type/lists`;
    const formData = new FormData();
    formData.append('user_id', id);
    formData.append('department_code', department_code);
    return this.http.post<{ data: { default_hours_type: string,hour_types: any[] } }>(
      url,
      formData,
      this.getOptions()
    );
  }

  // tslint:disable-next-line:variable-name
  fetchTimeEntries(
    start_date: string,
    end_date: string,
    employee_id: string
  ): Observable<HttpResponse<any>> {
    const data = {
      start_date,
      end_date,
      employee_id: Number(employee_id),
    };
    const url = `${this.API_ROOT}/time-manager/list`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchAddNonPedigreeTime(data: { user_id, formattedTimeIntervals, deletedTimeIntervalIds })
  : Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/time-manager/save-manual-hours`;
    return this.http.post<any[]>(url, {
      data: data.formattedTimeIntervals,
      delete_ids: data.deletedTimeIntervalIds
    }, this.getOptions());
  }

  fetchNonPedigreeTime(data: { user_id: string, start_hours: string, end_hours: string}): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/time-manager/get-manual-hours`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  // tslint:disable-next-line:variable-name
  fetchDeleteTimeEntry(time_entry_id: string): Observable<HttpResponse<any>> {
    const data = {
      time_entry_id,
    };
    const url = `${this.API_ROOT}/time-manager/delete`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchUpdateTimeEntries(
    timeEntries: TimeEntryModel[],
    status: string,
    time_difference: string | null = null
  ) {
    const data = {
      data: this.formatTimeEntries(timeEntries, status),
      time_difference,
    };
    const url = `${this.API_ROOT}/time-manager/save`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  formatTimeEntries(timeEntries: (TimeEntryModel | any)[], status: string) {
    const formattedEntries = [];

    timeEntries.forEach((entry) => {
      console.log(entry);
      const timeEntry = {
        time_entry: {
          id: entry.id ? entry.id : null,
          employee_id: entry.employee_id,
          location_id: entry.location_id,
          department_external_id: entry.department_external_id,
          job_id: entry.job_id,
          hours_type: entry.hours_type,
          department_id: entry.department_id,
          entry_flag: entry.entry_flag,
          clockin_date: moment(entry.clockin_date, 'YYYY/MM/DD').format(
            'YYYY/MM/DD'
          ),
          day_total: entry.hours,
          truck_number: entry.truck_number,
          unit_1: entry.unit_1,
          unit_2: entry.unit_2,
          location_from: entry.location_from,
          location_to: entry.location_to,
          ticket: entry.ticket,
          net_load: entry.net_load,
          tn_cy: entry.tn_cy,
          break_time: entry.break_time,
          time_entry_status: entry.time_entry_status,
          is_approved: status,
          number_of_tickets: entry.number_of_tickets || '0',
          pedigree_hours: entry.pedigree_hours,
          job_labor_code_id: entry.job_labor_code_id,
          elmers_scale: entry.elmers_scale,
          elmers_manual: entry.elmers_manual,
          elmers_vendor: entry.elmers_vendor,
          status: entry.from_mplus ? '1' : entry.status
        },
        material: [],
        equipment: entry.equipment.map((e) => {
          let temp: any = {};
          temp = {
            equipment_id: e.equipment_id,
            hourly_rate: e.hourly_rate,
            status: e.status,
          };
          if (e.id) {
            temp.id = e.id;
          }
          return temp;
        }),
        tow: entry.tow.map((e) => {
          let temp: any = {};
          temp = {
            tow_1_id: e.tow_1_id,
            tow_2_id: e.tow_2_id,
            status: '1',
          };
          if (e.id) {
            temp.id = e.id;
          }
          return temp;
        }),
        delete_equipment_ids: [...(entry.delete_equipment_ids || [])],
        delete_tow_ids: [...(entry.delete_tow_ids || [])],
        images:
          status === '4'
            ? [...(entry.images || [])]
            : [...(entry.photos || [])],
        notes: entry.notes,
        delete_note_ids: entry.delete_note_ids
      };
      console.log(timeEntry);
      formattedEntries.push(timeEntry);
    });
    console.log(formattedEntries);
    return formattedEntries;
  }

  fetchAddTimeEntryPhotos(base64Url: string): Observable<HttpResponse<any>> {
    const data = {
      data: {
        images: `${base64Url}`,
      },
    };
    const url = `${this.API_ROOT}/time-manager/save_image`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchTimeEntryPhotos(
    imagesData: { id: string; time_entry_photo: string }[]
  ): Observable<HttpResponse<any>> {
    const data = {
      data: {
        images: imagesData,
      },
    };
    const url = `${this.API_ROOT}/time-manager/get_image`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchDeleteTimeEntryPhoto(
    imagesNames: string[]
  ): Observable<HttpResponse<any>> {
    const data = {
      data: {
        images: imagesNames,
      },
    };
    console.log(data);
    const url = `${this.API_ROOT}/time-manager/delete_image`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchPTOTime(data: { user_id: string, start_date: string, end_date: string}): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/pto/list`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
