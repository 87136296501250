import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LocationModel } from '../backend/location/model/location.model';
import { ScheduledJobModel } from '../backend/schedule/model/scheduled-job.model';

export interface ScheduleState2Interface extends EntityState<ScheduledJobModel> {
  published_at: string;
  scheduleDetails: any;
  scheduledDates: any [];
}

export function selectScheduledJobId(a: any): string {
  return a.id;
}

export const adapterSchedule2: EntityAdapter<ScheduledJobModel> = createEntityAdapter<
  ScheduledJobModel
>({
  selectId: selectScheduledJobId,
});

export const initScheduleState: ScheduleState2Interface = adapterSchedule2.getInitialState(
  {
    published_at: null,
    scheduleDetails: null,
    scheduledDates: []
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterSchedule2.getSelectors();
