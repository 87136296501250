import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FormsBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    'content-type': 'application/json',
    'Auth-Token':
      '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  // tslint:disable-next-line:variable-name
  fetchStartAudit(
    audit_start_time: string,
    auditor_name: string,
    form_id: string
  ): Observable<HttpResponse<any>> {
    const data = {
      audit_start_time,
      auditor_name,
      form_id,
    };
    const url = `${this.API_ROOT}/form/start-audit`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  // tslint:disable-next-line:variable-name
  fetchSubmitResponses(
    audit_token: string,
    answers: any,
    positive_response: string,
    user_id: string
  ): Observable<HttpResponse<any>> {
    const data = {
      audit_token,
      answers,
      overall_comments: '',
      positive_response,
      user_id,
    };
    const url = `${this.API_ROOT}/form/submit-audit`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
