import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../backend/alert-service/alert.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private alertService: AlertService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwtToken = this.localStorageService.getItem('jwt');
    const HEADERS = new HttpHeaders({
      'Auth-Token':
        '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
      Authorization: `${jwtToken}`,
    });

    // Handle switch to Staging API
    if (localStorage.getItem('activeAPI') === '1') {
      req = req.clone({url: req.url.replace(environment.apiUrl, environment.stagingApiUrl)});
    }

    if (jwtToken) {
      const cloned = req.clone({
        headers: HEADERS,
        withCredentials: false,
      });
      return next.handle(cloned).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              if (this.localStorageService.getItem('jwt')) {
                this.localStorageService.removeItem('jwt');
                this.router.navigate(['/']);
                const alert = this.alertService.presentAlert(
                  '',
                  'Unauthorized',
                  [
                    {
                      text: 'Ok',
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: () => {
                        // do nothing for now
                      },
                    },
                  ]
                );
              }
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
