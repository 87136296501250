import * as AuthSessionActions from './auth-session.actions';
import {
  AuthSessionStateInterface,
  initAuthSessionStateInterface,
} from './auth-session.state';

export function AuthSessionReducer(
  state = initAuthSessionStateInterface,
  action
): AuthSessionStateInterface {
  switch (action.type) {
    case AuthSessionActions.LoginReceiveAction.type: {
      return {
        ...state,
        activeUser: action.payload,
      };
    }
    case AuthSessionActions.LogoutRequestAction.type: {
      return {
        ...state,
        activeUser: null,
      };
    }

    default:
      return state;
  }
}
