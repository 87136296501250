import * as TimeEntryActions from './time-entry.actions';
import {
  adapterTimeEntry,
  initTimeEntryState,
  TimeEntryStateInterface,
} from './time-entry.state';
import * as moment from 'moment';

export function TimeEntryReducer(
  state = initTimeEntryState,
  action
): TimeEntryStateInterface {
  switch (action.type) {
    case TimeEntryActions.TimeEntriesRequestAction.type: {
      const payload = action.payload;
      return {
        ...state,
        activeRangeStartDate: payload.startDate,
        activeRangeEndDate: payload.endDate,
        activeDate: state.activeDate,
        editedTimeEntry: state.editedTimeEntry,
        pendingTimeEntries: state.pendingTimeEntries,
      };
    }

    case TimeEntryActions.TimeEntriesReceiveAction.type: {
      const payload = action.payload.data;
      // convert date since forked implementation receives date as MM/DD/YYYY
      const formattedTimeEntries = [];
      for (const entry of payload) {
        formattedTimeEntries.push({
          ...entry,
          ...{
            created_date: moment(entry.created_date, 'YYYY/MM/DD').format(
              'MM/DD/YYYY'
            ),
          },
        });
      }
      let images = [];
      formattedTimeEntries.forEach((timeEntry) => {
        const photos = Object.values(timeEntry.photos);
        if (photos.length > 0) {
          images = [...images, ...photos];
        }
      });
      return adapterTimeEntry.upsertMany(formattedTimeEntries, {
        ...state,
        images,
      }); // add or update time entries
    }

    case TimeEntryActions.AddTimeEntryPhotosReceiveAction.type: {
      const payload = action.payload;
      return {
        ...state,
        images: [
          ...state.images,
          ...[
            {
              image: payload.imageName,
              base64Url: payload.base64Url,
              id: null,
            },
          ],
        ],
      };
    }

    case TimeEntryActions.TimeEntryPhotosReceiveAction.type: {
      const payload = action.payload.data.images;
      const images = [];
      payload.forEach((imageData) => {
        const currentImage = state.images.find(
          (image) => image.id === imageData.id
        );
        if (currentImage) {
          images.push({ ...currentImage, ...{ base64Url: imageData.time_entry_photo } });
        }
      });
      return {
        ...state,
        images,
      };
    }

    case TimeEntryActions.DeleteTimeEntryPhotosReceiveAction.type: {
      const payload: string[] = action.payload.imagesNames;
      return {
        ...state,
        images: state.images.filter((image) => !payload?.includes(image.image)),
      };
    }

    // case TimeEntryActions.UpdateTimeEntriesReceiveAction.type: {
    //     const payload = action.payload.timeEntries;
    //     // convert date since forked implementation receives date as MM/DD/YYYY
    //     const formattedTimeEntries = [];
    //     for (const entry of payload) {
    //         formattedTimeEntries.push({...entry, ...{ created_date: moment(entry.created_date, 'YYYY/MM/DD').format('MM/DD/YYYY')}});
    //     }
    //     return adapterTimeEntry.upsertMany(formattedTimeEntries, {...state}); // add or update time entries
    // }

    case TimeEntryActions.SetActiveDateAction.type: {
      const payload = action.payload.activeDate;
      return {
        ...state,
        activeRangeStartDate: state.activeRangeStartDate,
        activeRangeEndDate: state.activeRangeEndDate,
        activeDate: payload,
        editedTimeEntry: state.editedTimeEntry,
        pendingTimeEntries: state.pendingTimeEntries,
      };
    }

    case TimeEntryActions.ClearActiveDateAction.type: {
      return {
        ...state,
        activeRangeStartDate: state.activeRangeStartDate,
        activeRangeEndDate: state.activeRangeEndDate,
        activeDate: null,
        editedTimeEntry: state.editedTimeEntry,
        pendingTimeEntries: state.pendingTimeEntries,
      };
    }

    case TimeEntryActions.SetEditedTimeEntryAction.type: {
      const payload = action.payload.timeEntry;
      return {
        ...state,
        activeRangeStartDate: state.activeRangeStartDate,
        activeRangeEndDate: state.activeRangeEndDate,
        activeDate: state.activeDate,
        editedTimeEntry: payload,
        pendingTimeEntries: state.pendingTimeEntries,
      };
    }

    case TimeEntryActions.ClearEditedTimeEntryAction.type: {
      return {
        ...state,
        activeRangeStartDate: state.activeRangeStartDate,
        activeRangeEndDate: state.activeRangeEndDate,
        activeDate: state.activeDate,
        editedTimeEntry: null,
        pendingTimeEntries: state.pendingTimeEntries,
      };
    }

    case TimeEntryActions.SetPendingTimeEntriesAction.type: {
      const payload = action.payload.timeEntries;
      return {
        ...state,
        pendingTimeEntries: payload,
      };
    }

    case TimeEntryActions.ClearPendingTimeEntriesAction.type: {
      return {
        ...state,
        pendingTimeEntries: null,
      };
    }

    case TimeEntryActions.DeleteTimeEntryReceiveAction.type: {
      const payload = action.payload.timeEntryId;
      return adapterTimeEntry.removeOne(payload, state);
    }

      case TimeEntryActions.AddNonPedigreeTimeReceiveAction.type: {
        const payload = Object.values(action.payload.data);
        return {
              ...state,
              nonPedigreeTimes: [...state.nonPedigreeTimes, ...payload]
          };
      }

      case TimeEntryActions.GetNonPedigreeTimeReceiveAction.type: {
          const payload = Object.values(action.payload.data);
          return {
              ...state,
              nonPedigreeTimes: payload
          };
      }

    case TimeEntryActions.PTOReceiveAction.type: {
      const payload = Object.values(action.payload.data);
      return {
        ...state,
        ptoTimes: payload
      };
    }

    default:
      return state;
  }
}
