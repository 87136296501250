import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ScheduleActions from './schedule.actions';
import * as ErrorActions from '../error/error.actions';
import { map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { ScheduleService } from '../backend/schedule/schedule.service';

@Injectable()
export class Schedule2Effects {
  constructor(
    private actions$: Actions,
    private scheduleService: ScheduleService
  ) {}

  @Effect()
  OnRequestSchedule$ = this.actions$.pipe(
    ofType(ScheduleActions.RequestSchedule2Action),
    map((action) => action.payload),
    switchMap((requestDetails: any) =>
      from(this.scheduleService.getSchedule2(requestDetails)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data?.status !== 'error',
            of(ScheduleActions.ReceiveSchedule2Action({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );

  @Effect()
  OnRequestScheduleDetails$ = this.actions$.pipe(
    ofType(ScheduleActions.RequestScheduleDetailsAction),
    map((action) => action.payload),
    switchMap((requestDetails: any) =>
      from(this.scheduleService.getSchedule2Details(requestDetails)).pipe(
        mergeMap((data: any) =>
          iif(
            () => !!data && data?.status !== 'error',
            of(ScheduleActions.ReceiveScheduleDetailAction({ payload: data })),
            of(ErrorActions.ErrorReceiveAction({ payload: data.message }))
          )
        )
      )
    )
  );
}
