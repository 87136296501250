import { createSelector } from '@ngrx/store';
import { PlantStateInterface, selectAll } from './plant.state';
import { getPlantState } from '../store/store.selectors';

export const getAllPlants = createSelector(
  getPlantState,
  (state: PlantStateInterface) => selectAll(state)
);

export const getMSHAInspections = createSelector(
  getPlantState,
  (state: PlantStateInterface) => state.mshaInspections
);
