import { createAction, props } from '@ngrx/store';
import { FuelLogModel } from '../backend/fuel-logs/model/fuel-log.model';

export const CreateRequestAction = createAction('[Fuel Logs] Request Create',
  props<{ payload: { fuelLog: FuelLogModel } }>()
);

export const RequestAction = createAction(
  '[Fuel Logs] Request',
  props<{ payload: any }>()
);

export const ReceiveAction = createAction(
  '[Fuel Logs] Receive',
  props<{ payload: { fuelLogs: FuelLogModel[] } }>()
);

export const LocationsRequestAction = createAction('[Fuel Logs] Request Locations',
);

export const LocationsReceiveAction = createAction('[Fuel Logs] Receive Locations',
  props<{ payload: any }>()
);

export const FuelTypeRequestAction = createAction('[Fuel Logs] Request Fuel Type',
);

export const FuelTypeReceiveAction = createAction('[Fuel Logs] Receive Fuel Type',
  props<{ payload: any }>()
);

export const RequestDeleteAction = createAction(
  '[Fuel Logs] Request Delete',
  props<{ payload: number }>()
);

export const ReceiveDeleteAction = createAction(
  '[Fuel Logs] Receive Delete',
  props<{ payload: number }>()
);

export const RequestAddPhotoAction = createAction(
  '[Fuel Logs] Request Add Photo',
  props<{ payload: { base64Url } }>()
);

export const ReceiveAddPhotoAction = createAction(
  '[Fuel Logs] Receive Add Photo',
  props<{ payload: any }>()
);
