import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private localStorageService: LocalStorageService) {}

  canActivate(): boolean {
    // return this.localStorageService.getItem('jwt') && this.localStorageService.getItem('jwt') !== '';
    return (
      this.localStorageService.getItem('jwt') &&
      this.localStorageService.getItem('jwt') !== ''
    );
  }
}
