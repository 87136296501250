import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchableItemsListPage } from 'src/app/shared/modals/searchable-items-list/searchable-items-list.page';

@Component({
  selector: 'app-equipment-manager',
  templateUrl: './equipment-manager.component.html',
  styleUrls: ['./equipment-manager.component.scss'],
})
export class EquipmentManagerComponent implements OnInit {
  // I have to make a copy so that I can edit
  equipmentCopy: any[] = [];

  @Input() title: string;
  @Input() selectedItems: any[] = [];
  @Input() items: any[] = [];
  @Input() removed: string[] = [];
  @Input() jobName: string = '';
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.equipmentCopy = this.selectedItems.map((e) => ({ ...e }));
    this.equipmentCopy.forEach((e) => {
      e.name = this.items.find((i) => i.id === e.equipment_id).name;
    });
  }

  async selectEquipment(index) {
    try {
      const modal = await this.modalController.create({
        component: SearchableItemsListPage,
        componentProps: {
          items: this.items,
        },
      });

      await modal.present();
      modal.onDidDismiss().then((data) => {
        if (data.data?.selectedItem?.id) {
          this.equipmentCopy[index].equipment_id = data.data.selectedItem.id;
          this.equipmentCopy[index].name = data.data.selectedItem.name;
        }
      });
    } catch (error) {
      console.log(error);
    }
    // todo: this is no way to handle errors
  }

  onAddRow() {
    this.equipmentCopy.push({
      equipment_id: '',
      hourly_rate: '1',
      status: '1',
    });
  }

  decreaseHours(index) {
    if (+this.equipmentCopy[index].hourly_rate > 0.5) {
      this.equipmentCopy[index].hourly_rate =
        +this.equipmentCopy[index].hourly_rate - 0.5;
    }
  }
  increaseHours(index) {
    this.equipmentCopy[index].hourly_rate =
      +this.equipmentCopy[index].hourly_rate + 0.5;
  }
  onDuplicateRow(index) {
    let { equipment_id, hourly_rate, status, name } = this.equipmentCopy[index];
    this.equipmentCopy.splice(index, 0, {
      equipment_id,
      hourly_rate,
      status,
      name,
    });
  }
  onRemoveRow(index) {
    let removedItem = this.equipmentCopy.splice(index, 1);
    if (removedItem[0].id) {
      this.removed.push(removedItem[0].id);
    }
  }
  close() {
    this.modalController.dismiss();
  }
  submitItems() {
    this.modalController.dismiss({
      selectedItems: this.equipmentCopy.filter((e) => e.equipment_id),
      removed: this.removed,
    });
  }
}
