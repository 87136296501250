import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as LocationActions from './location.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as LocationSelectors from './location.selectors';
import { LocationModel } from '../backend/location/model/location.model';

@Injectable()
export class LocationStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public requestAllLocations() {
    this.store.dispatch(LocationActions.RequestAllLocationsAction());
  }
  public getAllLocations(): Observable<LocationModel[]> {
    return this.store.pipe(
      select((state) => LocationSelectors.getAllLocations(state))
    );
  }
}
