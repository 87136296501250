import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as FormsActions from './forms.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as FormsSelectors from './forms.selectors';

@Injectable()
export class FormsStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public initializeForm(date: string, userId: string, formId: string) {
    this.store.dispatch(
      FormsActions.FormsInitRequestAction({ payload: { date, userId, formId } })
    );
  }
  public getAuthToken(): Observable<string> {
    return this.store.pipe(
      select((state) => FormsSelectors.getActiveFormToken(state))
    );
  }
  public submitResponses(
    formToken: string,
    answers: string,
    positiveResponse: string,
    userId
  ) {
    this.store.dispatch(
      FormsActions.FormsSubmitResponsesRequestAction({
        payload: { formToken, answers, positiveResponse, userId },
      })
    );
  }
  // auditToken: string, answers: any, positiveResponse: string, userId: string
}
