import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlantModel } from '../backend/plant/model/plant.model';
import { FuelLogModel } from '../backend/fuel-logs/model/fuel-log.model';

export interface FuelLogsStateInterface extends EntityState<FuelLogModel> {
  pits: any[];
  vendors: any[];
  fuelTypes: any[];
  images: { image: string, base64Url: string, id: string } [];
}

export const adapterFuelLogs: EntityAdapter<FuelLogModel> = createEntityAdapter<
  FuelLogModel
>();

export const initFuelLogsState: FuelLogsStateInterface = adapterFuelLogs.getInitialState(
  {
    pits: [],
    vendors: [],
    fuelTypes: [],
    images: []
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterFuelLogs.getSelectors();
