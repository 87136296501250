import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AuthSessionSelectors from './auth-session/auth-session.selectors';
import * as AuthSessionActions from './auth-session/auth-session.actions';
import { SelectAppState } from '../store/store.state';
import { UserCredentials } from '../backend/auth/model/user-creds.model';
import { Subject } from 'rxjs';

@Injectable()
export class AuthStoreService {
  public loginResolved = new Subject<void>();
  public tempPassChangeRequired = new Subject<{
    user_id: string;
    jwtToken: string;
  }>();

  constructor(private store: Store<SelectAppState>) {}
  public initLogin(userCredential: UserCredentials) {
    this.store.dispatch(
      AuthSessionActions.LoginRequestAction({ payload: userCredential })
    );
  }
  public initChangeTemporaryPassword(
    user_id: string,
    password: string,
    jwtToken: string
  ) {
    this.store.dispatch(
      AuthSessionActions.ChangeTemporaryPasswordRequestAction({
        payload: { user_id, password, jwtToken },
      })
    );
  }
  public initForgotPassword(email: string) {
    this.store.dispatch(
      AuthSessionActions.ForgotPasswordRequestAction({ payload: { email } })
    );
  }
  public initPasswordReset(username: string, password: string, year: string) {
    this.store.dispatch(
        AuthSessionActions.PasswordResetRequestAction({ payload: { username, password, year } })
    );
  }
  public getActiveUser() {
    return this.store.pipe(
      select((state) => AuthSessionSelectors.getActiveUser(state))
    );
  }
  public logout() {
    this.store.dispatch(AuthSessionActions.LogoutRequestAction());
  }
}
