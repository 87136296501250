import { createSelector } from '@ngrx/store';
import { getJobState, getTimeEntriesState } from '../store/store.selectors';
import { JobStateInterface, selectAll } from './job.state';

export const getAllJobs = createSelector(
  getJobState,
  (state: JobStateInterface) => selectAll(state)
);

export const getSelectedJobMaterials = createSelector(
  getJobState,
  (state: JobStateInterface) => state.selectedJobMaterials
);

export const getSelectedJobEquipment = createSelector(
  getJobState,
  (state: JobStateInterface) => state.selectedJobEquipment
);

export const getSelectedJobCostCodes = createSelector(
  getJobState,
  (state: JobStateInterface) => state.selectedJobCostCodes
);
