import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FuelLogsBackend } from './fuel-logs.backend';
import { FuelLogModel } from './model/fuel-log.model';

@Injectable()
export class FuelLogsService extends BaseService {
  constructor(private fuelLogsBackend: FuelLogsBackend) {
    super();
  }

  addUpdate(data: FuelLogModel): Observable<any> {
    return this.fuelLogsBackend
      .fetchAddUpdateFuelLogs(data)
      .pipe(map((response) => response.body));
  }

  getPumpLocations(): Observable<any> {
    return this.fuelLogsBackend
      .fetchPumpLocations()
      .pipe(map((response) => response.body));
  }

  getFuelTypes(): Observable<any> {
    return this.fuelLogsBackend
      .fetchFuelTypes()
      .pipe(map((response) => response.body));
  }

  getFuelLogs(data: any): Observable<any> {
    return this.fuelLogsBackend
      .fetchFuelLogs(data)
      .pipe(map((response) => response.body));
  }

  deleteFuelLog(id: number): Observable<any> {
    return this.fuelLogsBackend
      .fetchDeleteFuelLog(id)
      .pipe(map((response) => response));
  }

  addPhoto(url: string): Observable<any> {
    return this.fuelLogsBackend
      .fetchAddPhoto(url)
      .pipe(map((response) => response.body));
  }
}
