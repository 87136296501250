import { createAction, props } from '@ngrx/store';

export const FormsInitRequestAction = createAction(
  '[Forms] Init Request',
  props<{ payload: { date: string; userId: string; formId: string } }>()
);

export const FormsInitReceiveAction = createAction(
  '[Forms] Init Receive',
  props<{ payload: { formToken: string } }>()
);

export const FormsSubmitResponsesRequestAction = createAction(
  '[Forms] Submit Responses Request',
  props<{
    payload: {
      formToken: string;
      answers: string;
      positiveResponse: string;
      userId;
    };
  }>()
);

export const FormsSubmitResponsesReceiveAction = createAction(
  '[Forms] Submit Responses Receive',
  props<{ payload: { positiveResponse: string } }>()
);
