import { createAction, props } from '@ngrx/store';

export const RequestSchedule2Action = createAction('[Schedule 2] Request',
  props<{ payload: { userId: string, startDate: string, endDate: string, division?: string[], jobId?: string } }>()
);

export const ReceiveSchedule2Action = createAction(
  '[Schedule 2] Receive',
  props<{ payload: any }>()
);

export const RequestScheduleDetailsAction = createAction('[Schedule 2] Request Details',
  props<{ payload: { jobScheduleId: string } }>()
);

export const ReceiveScheduleDetailAction = createAction(
  '[Schedule 2] Receive Details',
  props<{ payload: any }>()
);

export const ClearScheduleDetailsAction = createAction('[Schedule 2] Clear Details',
);
