import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeBackend } from './employee.backend';

@Injectable()
export class DepartmentService extends BaseService {
  constructor(private departmentBackend: EmployeeBackend) {
    super();
  }

  getAllDepartments(userId: string): Observable<any> {
    return this.departmentBackend
      .fetchAllEmployees()
      .pipe(map((response) => response.body));
  }
}
