import { createAction, props } from '@ngrx/store';
import { DepartmentModel } from '../backend/department/model/department.model';

export const RequestAllDepartmentsAction = createAction(
  '[Department] Request All'
);

export const ReceiveAllDepartmentsAction = createAction(
  '[Department] Receive All',
  props<{ payload: { departments: DepartmentModel[] } }>()
);
