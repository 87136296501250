import * as LocationActions from './location.actions';
import {
  adapterLocation,
  initLocationState,
  LocationStateInterface,
} from './location.state';

export function LocationReducer(
  state = initLocationState,
  action
): LocationStateInterface {
  switch (action.type) {
    case LocationActions.ReceiveAllLocationsAction.type: {
      const payload = action.payload;
      return adapterLocation.upsertMany(payload, { ...state }); // add or update time jobs
    }

    default:
      return state;
  }
}
