import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { SearchableItemsListPage } from 'src/app/shared/modals/searchable-items-list/searchable-items-list.page';

@Component({
  selector: 'app-tow-manager',
  templateUrl: './tow-manager.component.html',
  styleUrls: ['./tow-manager.component.scss'],
})
export class TowManagerComponent implements OnInit {
  // I have to make a copy so that I can edit
  towsCopy: any[] = [];
  isEditableCopy = true;

  @Input() title: string;
  @Input() selectedItems: any[] = [];
  @Input() items: any[] = [];
  @Input() removed: string[] = [];
  @Input() jobName: string = '';
  @Input() isEditable: boolean = true;
  constructor(
    private modalController: ModalController,
    private toastController: ToastController
  ) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.towsCopy = this.selectedItems.map((e) => ({ ...e }));
    this.towsCopy.forEach((e) => {
      e.name1 = this.items.find((i) => i.id === e.tow_1_id).name;
      e.name2 = this.items.find((i) => i.id === e.tow_2_id)?.name;
    });
    if (this.towsCopy.length === 0) {
      this.towsCopy.push({
        tow_1_id: null,
        tow_2_id: null,
        status: '1',
      });
    }
    this.isEditableCopy = this.isEditable;
  }

  async selectTow(index, row) {
    let otherRow = row === '1' ? '2' : '1';
    if (!this.isEditableCopy) {
      return;
    }
    try {
      const items = this.items.filter(
          (e) => e.id !== this.towsCopy[index]['tow_' + otherRow + '_id']
      );

      const modal = await this.modalController.create({
        component: SearchableItemsListPage,
        componentProps: {
          items: [
            { id: 'something_special', name: '* No Tow *' }, // as a delete button
            ...items
          ],
        },
      });

      await modal.present();
      modal.onDidDismiss().then((data) => {

        if (data.data?.selectedItem?.id) {
          // we need a special id to know it is delete action
          if (data.data.selectedItem.id === 'something_special') {
            this.towsCopy[index]['tow_' + row + '_id'] = '';
            this.towsCopy[index]['name' + row] = index === 0 ? 'Select Tow #1' : 'Select Tow #2';
          } else {
            this.towsCopy[index]['tow_' + row + '_id'] =
                data.data.selectedItem.id;
            this.towsCopy[index]['name' + row] = data.data.selectedItem.name;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    // todo: this is no way to handle errors
  }

  onRemoveRow(index) {
    let removedItem = this.towsCopy.splice(index, 1);
    if (removedItem[0].id) {
      this.removed.push(removedItem[0].id);
    }
    this.towsCopy.push({
      tow_1_id: null,
      tow_2_id: null,
      status: '1',
    });
  }
  close() {
    this.modalController.dismiss();
  }
  async submitItems() {
    if (!this.towsCopy[0]?.tow_1_id) {
      const toast = await this.toastController.create({
        message: 'Tow 1 not entered, no tows saved.',
        duration: 700,
        position: 'top',
        color: 'warning',
      });
      toast.present();
    }
    this.modalController.dismiss({
      selectedItems: this.towsCopy.filter((e) => e.tow_1_id),
      removed: this.removed,
    });
  }
}
