import * as ScheduleActions from './schedule.actions';
import {
  adapterSchedule2,
  initScheduleState, ScheduleState2Interface
} from './schedule.state';

export function Schedule2Reducer(
  state = initScheduleState,
  action
): ScheduleState2Interface {
  switch (action.type) {

    case ScheduleActions.RequestSchedule2Action.type: {
      return adapterSchedule2.removeAll(state);
    }

    case ScheduleActions.ReceiveSchedule2Action.type: {
      const payload = action.payload;
      const scheduledDates = Object.entries(payload.published_date_data).map(item => {
        return { date: item[0], isPublished: item[1] === 1};
      });
      const schedule = (Object.values(payload.data) as any);


      if (!payload.data) {
        return {
          ...state, ...{
            published_at: 'No Data Available',
            scheduledDates
          }
        };
      }
      console.log(schedule);
      return adapterSchedule2.addMany(schedule, { ...state, ...{
        published_at: payload.published_at,
          scheduledDates
        } });
    }

    case ScheduleActions.ReceiveScheduleDetailAction.type: {
      const payload = action.payload.data;
      return {
        ...state, ...{
          scheduleDetails: payload
        }
      };
    }

    case ScheduleActions.ClearScheduleDetailsAction.type: {
      return {
        ...state, ...{
          scheduleDetails: null
        }
      };
    }

    default:
      return state;
  }
}
