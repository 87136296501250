import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DepartmentBackend } from './department.backend';

@Injectable()
export class DepartmentService extends BaseService {
  constructor(private departmentBackend: DepartmentBackend) {
    super();
  }

  getAllDepartments(userId: string): Observable<any> {
    return this.departmentBackend
      .fetchAllDepartments(userId)
      .pipe(map((response) => response.body));
  }
}
