import * as DepartmentActions from './department.actions';
import {
  adapterDepartment,
  DepartmentStateInterface,
  initDepartmentState,
} from './department.state';

export function DepartmentReducer(
  state = initDepartmentState,
  action
): DepartmentStateInterface {
  switch (action.type) {
    case DepartmentActions.ReceiveAllDepartmentsAction.type: {
      const payload = action.payload.data.departments;
      return adapterDepartment.upsertMany(payload, { ...state });
    }

    default:
      return state;
  }
}
