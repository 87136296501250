import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LocationModel } from '../backend/location/model/location.model';

export interface LocationStateInterface extends EntityState<LocationModel> {}

export const adapterLocation: EntityAdapter<LocationModel> = createEntityAdapter<
  LocationModel
>();

export const initLocationState: LocationStateInterface = adapterLocation.getInitialState(
  {}
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterLocation.getSelectors();
