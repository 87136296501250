import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as FuelLogsActions from './fuel-logs.actions';
import { SelectAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import { FuelLogModel } from '../backend/fuel-logs/model/fuel-log.model';
import * as FuelLogsSelectors from './fuel-logs.selectors';
import * as TimeEntryActions from '../time-entry/time-entry.actions';
import * as TimeEntrySelectors from '../time-entry/time-entry.selectors';

@Injectable()
export class FuelLogsStoreService {
  constructor(private store: Store<SelectAppState>) {}

  public addUpdate(data: FuelLogModel) {
    this.store.dispatch(FuelLogsActions.CreateRequestAction({payload: { fuelLog: data}}));
  }

  public requestFuelLogs(data: any) {
    this.store.dispatch(FuelLogsActions.RequestAction(data));
  }

  public requestLocations() {
    this.store.dispatch(FuelLogsActions.LocationsRequestAction());
  }

  public getLocations(): Observable<any> {
    return this.store.pipe(
      select((state) => FuelLogsSelectors.getLocations(state))
    );
  }

  public requestFuelTypes() {
    this.store.dispatch(FuelLogsActions.FuelTypeRequestAction());
  }

  public getFuelTypes(): Observable<any> {
    return this.store.pipe(
      select((state) => FuelLogsSelectors.getFuelTypes(state))
    );
  }

  public getAllFuelLogs(): Observable<any> {
    return this.store.pipe(
      select((state) => FuelLogsSelectors.getAllFuelLogs(state))
    );
  }

  public delete(id: number) {
    this.store.dispatch(FuelLogsActions.RequestDeleteAction({
      payload: id
    }));
  }

  public addPhoto(base64Url: string) {
    this.store.dispatch(
      FuelLogsActions.RequestAddPhotoAction({
        payload: { base64Url },
      })
    );
  }

  public getAllPhotos(): Observable<any[]> {
    return this.store.pipe(
      select((state) => FuelLogsSelectors.getAllPhotos(state))
    );
  }
}
