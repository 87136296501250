import * as PedigreeActions from './pedigree.actions';
import {
  adapterPedigree,
  initPedigreeState,
  PedigreeStateInterface,
} from './pedigree.state';

export function PedigreeReducer(
  state = initPedigreeState,
  action
): PedigreeStateInterface {
  switch (action.type) {
    case PedigreeActions.PedigreeEntriesReceiveAction.type: {
      const payload = action.payload.data.on_duty;
      const formattedTimes = [];
      Object.keys(payload).map((key) => {
        formattedTimes.push({
          id: key,
          hours: payload[key].hours,
        });
        return formattedTimes;
      });
      return adapterPedigree.upsertMany(formattedTimes, { ...state });
    }

    default:
      return state;
  }
}
