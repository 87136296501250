import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { filter, map, tap } from 'rxjs/operators';
import { AuthBackend } from './auth.backend';
import { Observable } from 'rxjs';
import { UserCredentials } from './model/user-creds.model';

@Injectable()
export class AuthService extends BaseService {
  constructor(private authBackend: AuthBackend) {
    super();
  }

  login(userCredentials: UserCredentials): Observable<any> {
    return this.authBackend
      .fetchLogin(userCredentials)
      .pipe(map((response) => response.body));
  }

  changeTempPass(user_id, password, jwtToken): Observable<any> {
    return this.authBackend
      .fetchChangeTemporaryPassword(user_id, password, jwtToken)
      .pipe(map((response) => response.body));
  }

  forgotPassword(email: string): Observable<any | ErrorMessage> {
    return this.authBackend
      .fetchForgotPassword(email)
      .pipe(map((response) => response.body));
  }

  resetPassword(username: string, password: string, year: string): Observable<any | ErrorMessage> {
    return this.authBackend
        .fetchResetPassword(username, password, year)
        .pipe(map((response) => response.body));
  }
}
