import { createSelector } from '@ngrx/store';
import {
  getPedigreeState,
  getTimeEntriesState,
} from '../store/store.selectors';
import * as moment from 'moment';
import { getActiveUser } from '../auth/auth-session/auth-session.selectors';
import { UserBackendModel } from '../backend/auth/model/user-backend.model';
import { of } from 'rxjs';
import { PedigreeStateInterface, selectAll } from './pedigree.state';
import {
  getActiveEndDate,
  getActiveStartDate,
} from '../time-entry/time-entry.selectors';
import { PedigreeTimeModel } from '../backend/pedigree/model/pedigree-time.model';

export const getAllPedigreeEntries = createSelector(
  getPedigreeState,
  (state: PedigreeStateInterface) => selectAll(state)
);

// export const getActivePedigreeEntries = createSelector(
//     getAllPedigreeEntries,
//     getActiveStartDate,
//     getActiveEndDate,
//     (allEntries: PedigreeTimeModel[], startDate: string, endDate: string) => {
//         const rangeStartDate = moment(startDate, 'MM/DD/YYYY').subtract(1, 'days');
//         const rangeEndDate = moment(endDate, 'MM/DD/YYYY').add(1, 'days');
//         return allEntries.filter((timeEntry) =>
//             moment(timeEntry.start_date, 'MM/DD/YYYY').isBetween(rangeStartDate, rangeEndDate, 'date')
//         );
//     }
// );
//
// export const getTimeEntriesByDate = createSelector(
//     getActiveEntries,
//     (activeTimeEntries: TimeEntryModel[], date: string) => {
//         return activeTimeEntries.filter(timeEntry => timeEntry.start_date === date);
//     }
// );
