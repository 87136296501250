import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { JobModule } from './job/job.module';
import { TimeEntryModule } from './time-entry/time-entry.module';
import { FormsModule } from './forms/forms.module';
import { PedigreeModule } from './pedigree/pedigree.module';
import { DepartmentModule } from './department/department.module';
import { LocationModule } from './location/location.module';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { ScheduleModule } from './schedule/schedule.module';
import { PlantModule } from './plant/plant.module';
import { FuelLogsModule } from './fuel-logs/fuel-logs.module';
import { EmployeeModule } from './employee/employee.module';

@NgModule({
  imports: [
    AuthModule,
    JobModule,
    TimeEntryModule,
    FormsModule,
    PedigreeModule,
    DepartmentModule,
    LocationModule,
    ScheduleModule,
    PlantModule,
    FuelLogsModule,
    EmployeeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class BackendModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: BackendModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'BackendModule');
  }
}
